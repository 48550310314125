import { v4 as uuidv4 } from "uuid";
import { firestore, auth, functions } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

interface AvatarUploadURLResponse {
  signedUrl: string;
  objectUrl: string;
}

export class UploadAvatarService {
  static async saveAvatarToS3AndFirestore(file: File): Promise<void> {
    try {
      // Ensure there is a current user
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("No authenticated user found");

      // Generate a unique filename for the avatar
      const filename = `${uuidv4()}.${file.name.split('.').pop()}`;
      const generateUploadURL = httpsCallable(getFunctions(), 'generateUploadURL');

      // Correctly calling the cloud function and handling the promise
      const result = await generateUploadURL({ filename });

      // Now, ensure we're correctly accessing the data property
      // and using the defined interface for type assertion
      const { signedUrl, objectUrl } = result.data as AvatarUploadURLResponse;

      // Proceed with the upload using the signed URL
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      if (!response.ok) throw new Error('Failed to upload avatar to S3');
      console.log('Upload successful');

      // Update the user's document in Firestore with the new avatar URL
      const userDocRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        avatarUrl: objectUrl,
      });
      console.log('Firestore document updated with new avatar URL');

    } catch (error) {
      console.error('Error uploading avatar or saving URL:', error);
      throw error; // Rethrow or handle as needed
    }
  }
}
