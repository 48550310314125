import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Track } from "../Types/Types"

// Define an interface for the context value
interface SearchContextType {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  tracks: Track[]; // Replace `any` with your Track type
  setTracks: (tracks: any[]) => void; // Replace `any` with your Track type
}

interface SearchProviderProps {
  children?: ReactNode;
}

// Create the context with a default value
const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tracks, setTracks] = useState<Track[]>([]); 

  const value = {
    searchQuery,
    setSearchQuery,
    tracks,
    setTracks,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
