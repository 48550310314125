import React, { createContext, useContext, useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Define the structure of the context data
interface UserContextType {
  userType: string | null;
  setUserType: React.Dispatch<React.SetStateAction<string | null>>;
  error: Error | null;
  handleLogout: () => Promise<void>;
}

// Define a type for the provider props
interface UserContextProviderProps {
  children?: React.ReactNode;
}

const defaultContextValue: UserContextType = {
  userType: null,
  setUserType: () => {},
  error: null,
  handleLogout: async () => {},
};

// Create context with default value
export const UserContext = createContext<UserContextType>(defaultContextValue);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserContextProvider');
  }
  return context;
};

// UserContextProvider as a regular function
export function UserContextProvider({ children }: UserContextProviderProps) {
  const [userType, setUserType] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, fetch user type
        const uid = user.uid;
        try {
          const userDocRef = doc(db, 'users', uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserType(userData.userType); // Assuming 'userType' is the field name in Firestore
          } else {
            console.log('No such document!');
            setError(new Error('No user document found'));
          }
        } catch (error) {
          console.error('Error fetching user type:', error);
          setError(error as Error);
        }
      } else {
        // User is signed out
        setUserType(null);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleLogout = async () => {
    try {
      await getAuth().signOut();
      // Optionally reset user-related state here
      setUserType(null);
      // Redirect or perform additional cleanup
    } catch (error) {
      console.error("Logout error:", error);
      // Optionally update the context's error state
    }
  };

  return (
    <UserContext.Provider value={{ userType, setUserType, error, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
}
