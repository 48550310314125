import "../Styles/Feed.css"
import React, { useState, useEffect, useContext } from "react";
import { firestore, auth } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { AudioTrack } from "./AudioTrack"
import { FeedService } from "../Services/FeedService";
import { Track } from "../Types/Types"
import { AudioPlayerContext } from "../Context/AudioPlayerContext";



export const Feed = () => {
    const [tracks, setTracks] = useState<Track[]>([]);
    const [filter, setFilter] = useState<string>("recent");

    useEffect(() => {
        const auth = getAuth();

        // This sets up an observer on the Auth object to listen for changes in the user's sign-in state.
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userId = user.uid;
                // Within the useEffect in your Feed component
                const fetchTracks = async () => {
                    const fetchedTracks = await FeedService.getFeed(userId, filter);
                    setTracks(fetchedTracks);
                };
                fetchTracks();
            } else {
                // User is signed out
                setTracks([]); // Optionally clear tracks if user is not signed in
            }
        });

        // Clean up the subscription on unmount
        return () => unsubscribe();
    }, [filter]);

    const shuffleArray = (array: Track[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const randomizeTracks = () => {
        setTracks(prevTracks => shuffleArray([...prevTracks]));
    };

    const handleFilterChange = (newFilter: string) => {
        setFilter(newFilter);
    };



    return (
        <div className='feed-container'>
            <div className='filter-btn-container'>
                <button
                    className={`feed-filter-btn ${filter === 'recent' ? 'selected' : ''}`}
                    onClick={() => handleFilterChange('recent')}
                >
                    Recent
                </button>
                <button
                    className={`feed-filter-btn ${filter === 'singles' ? 'selected' : ''}`}
                    onClick={() => handleFilterChange('singles')}
                >
                    Singles
                </button>
                <button
                    className={`feed-filter-btn ${filter === 'albums' ? 'selected' : ''}`}
                    onClick={() => handleFilterChange('albums')}
                >
                    Albums
                </button>
                <button
                    className={`feed-filter-btn ${filter === 'randomize' ? 'selected' : ''}`}
                    onClick={() => { handleFilterChange('randomize'); randomizeTracks(); }}
                >
                    Randomize
                </button>
            </div>
            <section className={`feed-tracks-container ${tracks.length > 0 ? 'not-empty' : 'empty'}`}>
                {tracks.map((track) => (
                    <AudioTrack key={track.id} track={track} feedIdentifier="latestreleases" feedTracks={tracks} />
                ))}
            </section>

        </div>
    )
}