import React, { useEffect, useState, useContext } from "react";
import { AudioTrack } from "./AudioTrack";
import '../Styles/LikedSongs.css';
import { auth } from "../firebase"; 
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs, doc, getDoc, orderBy } from "firebase/firestore";
import { firestore } from "../firebase"; 
import { Track } from '../Types/Types'
import { AudioPlayerContext } from '../Context/AudioPlayerContext';



export const LikedSongs = ()  => {
  const [likedTracks, setLikedTracks] = useState<Track[]>([]);
  const [userId, setUserId] = useState<string | null>(null);


  // Listener for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        // Potentially fetch liked songs here or in a separate effect
      } else {
        setUserId(null);
        setLikedTracks([]); // Clear liked tracks if no user is signed in
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch liked songs when userId changes and is not null
  useEffect(() => {
    const fetchLikedSongs = async () => {
      if (userId) {
        const q = query(
          collection(firestore, "likedTracks"), 
          where("userId", "==", userId),
          orderBy("likeDate", "desc")
        );
        const querySnapshot = await getDocs(q);

        const tracksPromises = querySnapshot.docs.map(async (doc) => {
          const trackData = doc.data();
          return fetchTrackDetails(trackData.trackId);
        });

        const tracks = (await Promise.all(tracksPromises)).filter((track): track is Track => track !== null);
        setLikedTracks(tracks);
      }
    };

    fetchLikedSongs();
  }, [userId]); // Added setPlaylist to the dependency array to ensure it's captured by the effect
  
  

  const fetchTrackDetails = async (trackId: string): Promise<Track | null> => {
    const trackRef = doc(firestore, "tracks", trackId);
    const trackSnap = await getDoc(trackRef);
    if (trackSnap.exists()) {
      return { id: trackId, ...trackSnap.data() } as Track;
    } else {
      console.error("Track not found", trackId);
      return null;
    }
  };
  

  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <h1 className='header-title'>Liked Songs</h1>
          <section>
            {likedTracks.map((track) => (
              <AudioTrack key={track.id} track={track} feedIdentifier="likes" feedTracks={likedTracks}/>
            ))}
          </section>
        </section>
      </section>
    </div>
  );
};

