import React, { useState } from "react";
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import '../Styles/InfringementForm.css';
import {
    validateFirstName,
    validateLastName,
    validateEmail,
    validateClaimType,
    validateContentType,
    validateClaimDetails,
    validateClaimInfo,
    validateAgreement1,
    validateAgreement2,
    validateAgreement3,
    validateFullName
} from "../Services/InfringementFormValidation";
import { Spinner } from "../GlobalStyles/Spinner"
import { InfringementFormValues, InfringementFormErrors } from "../Types/Types"
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";






export const InfringementForm = () => {
    const logoUrl: string = "https://augio-other-images.s3.amazonaws.com/Augio_FullType_Logo_latest.png";
    const [sending, setSending] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [infringementFormData, setInfringementFormData] = useState<InfringementFormValues>({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        claimType: "",
        contentType: "",
        claimInfo: "",
        claimDetails: "",
        agreement1: false,
        agreement2: false,
        agreement3: false,
        fullName: ""
    });

    const [errors, setErrors] = useState<InfringementFormErrors>({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        claimType: "",
        contentType: "",
        claimInfo: "",
        claimDetails: "",
        agreement1: "",
        agreement2: "",
        agreement3: "",
        fullName: ""
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const form = event.currentTarget;

        const newErrors = {
            firstName: validateFirstName(infringementFormData.firstName),
            lastName: validateLastName(infringementFormData.lastName),
            phoneNumber: "",
            email: validateEmail(infringementFormData.email),
            claimType: validateClaimType(infringementFormData.claimType),
            contentType: validateContentType(infringementFormData.contentType),
            claimInfo: validateClaimInfo(infringementFormData.claimInfo),
            claimDetails: validateClaimDetails(infringementFormData.claimDetails),
            agreement1: validateAgreement1(infringementFormData.agreement1),
            agreement2: validateAgreement2(infringementFormData.agreement2),
            agreement3: validateAgreement3(infringementFormData.agreement3),
            fullName: validateFullName(infringementFormData.fullName)
        };

        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error !== "");
        if (!hasErrors) {
            setSending(true);
            emailjs.sendForm(
                process.env.REACT_APP_EMAILJS_SERVICE_ID!,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID!,
                form,
                process.env.REACT_APP_EMAILJS_USER_ID!
            ).then((result) => {
                console.log('Email successfully sent!', result.text);
                setSubmitted(true);
                setInfringementFormData({
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    claimType: "",
                    contentType: "",
                    claimInfo: "",
                    claimDetails: "",
                    agreement1: false,
                    agreement2: false,
                    agreement3: false,
                    fullName: ""
                });
            }, (error) => {
                console.error('Failed to send email:', error.text);
            }).finally(() => {
                setSending(false);
            });
        }
    };


    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement; // Safe to cast because we handle all inputs
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setInfringementFormData({
            ...infringementFormData,
            [target.name]: value
        });
    };




    return (
        <div className='copyright-infringement-container'>
            <Link to="/">
                <img src={logoUrl} alt="Logo" className="augio-logo-link" />
            </Link>
            <h3 className='copyright-infringement-header-text'>Submit a Copyright Infringement Claim</h3>
            <p className='copyright-infringement-subtext'>To notify Augio of an infringement or other legal claim relating to content on the
                Augio service, please complete the form below. Please note, your statements in this document may
                subject you to legal consequences. You may wish to consult an attorney before submitting a claim.</p>

            <form className='copyright-infringement-form' onSubmit={handleSubmit}>

                <label className='copyright-infringement-form-label'>First Name</label>
                <input className='copyright-infringement-input' name="firstName" value={infringementFormData.firstName} onChange={handleChange} />
                {errors.firstName && <div className="infringement-form-error">{errors.firstName}</div>}


                <label className='copyright-infringement-form-label'>Last Name</label>
                <input className='copyright-infringement-input' name="lastName" value={infringementFormData.lastName} onChange={handleChange} />
                {errors.lastName && <div className="infringement-form-error">{errors.lastName}</div>}


                <label className='copyright-infringement-form-label'>Phone Number</label>
                <input className='copyright-infringement-input' placeholder="(optional)" name="phoneNumber" value={infringementFormData.phoneNumber} onChange={handleChange} />

                <label className='copyright-infringement-form-label'>Email</label>
                <input className='copyright-infringement-input' name="email" value={infringementFormData.email} onChange={handleChange} />
                {errors.email && <div className="infringement-form-error">{errors.email}</div>}



                <label className='copyright-infringement-form-label'>Claim Type</label>
                <select className='copyright-infringement-input' name="claimType" value={infringementFormData.claimType} onChange={handleChange}>
                    <option value="Copyright" >Copyright</option>
                    <option value="Trademark" >Trademark</option>
                    <option value="Personal Image/Identity" >Personal Image/Identity</option>
                </select>
                {errors.claimType && <div className="infringement-form-error">{errors.claimType}</div>}


                <label className='copyright-infringement-form-label'>Content type you would like to report</label>
                <select className='copyright-infringement-input' name="contentType" value={infringementFormData.contentType} onChange={handleChange}>
                    <option value="Music" >Music</option>
                    <option value="Album/Song Artwork" >Album/Song Artwork</option>
                </select>
                {errors.contentType && <div className="infringement-form-error">{errors.contentType}</div>}


                <label className='copyright-infringement-form-label'>Please provide the name of the artist and song of the content you would like to report</label>
                <input className='copyright-infringement-input' name="claimInfo" value={infringementFormData.claimInfo} onChange={handleChange} />
                {errors.claimInfo && <div className="infringement-form-error">{errors.claimInfo}</div>}


                <label className='copyright-infringement-form-label'>
                    Summarize your claims and agree to the terms</label>
                <textarea className='copyright-infringement-textarea' placeholder="Provide details on the infringement claim" name="claimDetails" value={infringementFormData.claimDetails} onChange={handleChange} />
                {errors.claimDetails && <div className="infringement-form-error">{errors.claimDetails}</div>}


                <div className='infringe-agreement-container'>
                    <input className='infringe-agreement-checkbox' type='checkbox' name="agreement1" checked={infringementFormData.agreement1} onChange={handleChange}></input>
                    <label className='infringe-agreement-text'>I have good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law</label>
                </div>
                {errors.agreement1 && <div className="infringement-form-error">{errors.agreement1}</div>}


                <div className='infringement-agreements-group'>
                    <div className='infringe-agreement-container'>
                        <input className='infringe-agreement-checkbox' type='checkbox' name="agreement2" checked={infringementFormData.agreement2} onChange={handleChange}></input>
                        <label className='infringe-agreement-text'>The information in this notification is accurate, and
                            under penalty or perjury, I am the owner, or an agent authorized to act on behalf of the owner, of an
                            exclusive right that was allegedly infringed
                        </label>
                    </div>
                    {errors.agreement2 && <div className="infringement-form-error">{errors.agreement2}</div>}


                    <div className='infringe-agreement-container'>
                        <input className='infringe-agreement-checkbox' type='checkbox' name="agreement3" checked={infringementFormData.agreement3} onChange={handleChange}></input>
                        <label className='infringe-agreement-text'>I understand that abuse of this tool, such as
                            submitting removal requests for content I do not own, may result in termination of my Augio account
                        </label>
                    </div>
                    {errors.agreement3 && <div className="infringement-form-error">{errors.agreement3}</div>}

                </div>

                <label className='copyright-infringement-form-label'>Write Your Full Name to Confirm</label>
                <input className='copyright-infringement-input' name="fullName" value={infringementFormData.fullName} onChange={handleChange} />
                {errors.fullName && <div className="infringement-form-error">{errors.fullName}</div>}


                <button className='copyright-infringement-submit' disabled={sending}>
                    {sending ? <Spinner /> : 'Submit'}
                </button>

                {submitted && (
                    <p className='infringement-form-success-message'>
                        Your claim has been sent. The Augio team will respond within 48 hours. Thank you.
                    </p>
                )}
            </form>
        </div>
    )
}