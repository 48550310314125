import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  getDoc,
  doc, 
  limit,
  DocumentData
} from 'firebase/firestore';
import { Track } from "../Types/Types"

export class ProfileService {
  static async getTracksByUser(userName: string, filter: string = 'recent', limitNumber?: number) {
    const tracksRef = collection(firestore, "tracks");
    let q;

    switch (filter) {
      case 'recent':
        q = query(tracksRef, where("userName", "==", userName), orderBy("releaseDate", "desc"));
        break;
      case 'popular':
        q = query(tracksRef, where("userName", "==", userName), orderBy("allTimePlays", "desc"), orderBy("releaseDate", "asc"));
        break;
      case 'singles':
        q = query(tracksRef, where("userName", "==", userName), where("uploadType", "==", "single"), orderBy("releaseDate", "desc"));
        break;
      case 'albums':
        q = query(tracksRef, where("userName", "==", userName), where("uploadType", "==", "album"), orderBy("releaseDate", "desc"));
        break;
      default:
        q = query(tracksRef, where("userName", "==", userName), orderBy("releaseDate", "desc"));
    }

    if (limitNumber) {
      q = query(q, limit(limitNumber)); 
    }

    const querySnapshot = await getDocs(q);
    let tracks: Track[] = [];
    querySnapshot.docs.forEach(doc => {
      const trackData = doc.data() as DocumentData;
      tracks.push({
        id: doc.id,
        trackTitle: trackData.trackTitle,
        userName: trackData.userName,
        artistName: trackData.artistName,
        genre: trackData.genre,
        coverArtUrl: trackData.coverArtUrl,
        audioFileUrl: trackData.audioFileUrl,
        releaseDate: trackData.releaseDate?.toDate ? trackData.releaseDate.toDate() : trackData.releaseDate,
        featureArtists: trackData.featureArtists,
        producers: trackData.producers,
        songwriters: trackData.songwriters,
        engineers: trackData.engineers

      });
    });

    return tracks;
  }

  static async getUserDetails(userName: string) {
    const usersRef = collection(firestore, "users");
    const q = query(usersRef, where("userName", "==", userName));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // Assuming userName is unique and only one document should match
        const userDetails = querySnapshot.docs[0].data();
        console.log(`User found:`, userDetails);
        return userDetails; // Return the first matching document's data
    } else {
        console.error("User not found");
        throw new Error("User not found");
    }
  }

// New method to fetch region name by regionID
static async getRegionName(regionID: number) {
    const regionsRef = collection(firestore, "regions");
    // Query for documents where the 'regionID' field matches the numeric regionID you have
    const q = query(regionsRef, where("regionID", "==", regionID));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // Assuming the first document found is the correct one, and it contains a 'regionName' field
        const regionName = querySnapshot.docs[0].data().regionName; // Adjust if your field name is different
        console.log(`Region name found: ${regionName}`);
        return regionName;
    } else {
        console.error("Region not found for ID:", regionID);
        throw new Error("Region not found");
    }
}


}
