import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { User } from "firebase/auth";
import { ChartService } from "../Services/ChartService";
import { RegionService } from "../Services/RegionService";
import { PlaylistService } from "../Services/PlaylistService";

interface SideNavigationContextType {
  isFavoriteChartsExpanded: boolean;
  setIsFavoriteChartsExpanded: (isExpanded: boolean) => void;
  isFavoriteRegionsExpanded: boolean;
  setIsFavoriteRegionsExpanded: (isExpanded: boolean) => void;
  favoriteCharts: any[];
  setFavoriteCharts: (charts: any[]) => void;
  favoriteRegions: any[];
  setFavoriteRegions: (regions: any[]) => void;
  addFavoriteChart: (newChart: any) => void;
  removeFavoriteChart: (chartId: string) => void;
  addFavoriteRegion: (newRegion: any) => void;
  removeFavoriteRegion: (regionId: string) => void;
  isPlaylistsExpanded: boolean;
  setIsPlaylistsExpanded: (isExpanded: boolean) => void;
  playlists: any[];
  setPlaylists: (playlists: any[]) => void;
  addPlaylist: (newPlaylist: any) => void;
  removePlaylist: (playlistId: string) => void;
  editPlaylist: (playlistId: string, updatedName: string) => void;
  playlistModalMode: "create" | "edit" | "delete";
  setPlaylistModalMode: (mode: "create" | "edit" | "delete") => void;
}

interface SideNavigationProviderProps {
  children?: React.ReactNode;
}

const SideNavigationContext = createContext<
  SideNavigationContextType | undefined
>(undefined);

export const SideNavigationProvider = ({
  children,
}: SideNavigationProviderProps) => {
  const [isFavoriteChartsExpanded, setIsFavoriteChartsExpanded] =
    useState(true);
  const [isFavoriteRegionsExpanded, setIsFavoriteRegionsExpanded] =
    useState(true);
  const [favoriteCharts, setFavoriteCharts] = useState<any[]>([]);
  const [favoriteRegions, setFavoriteRegions] = useState<any[]>([]);
  const [isPlaylistsExpanded, setIsPlaylistsExpanded] = useState(false);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [playlistModalMode, setPlaylistModalMode] = useState<
    "create" | "edit" | "delete"
  >("create");

  const addFavoriteChart = (newChart: any) => {
    setFavoriteCharts((prevCharts) => [...prevCharts, newChart]);
  };

  const removeFavoriteChart = (chartId: any) => {
    setFavoriteCharts((prevCharts) =>
      prevCharts.filter((chart) => chart.id !== chartId)
    );
  };

  const addFavoriteRegion = (newRegion: any) => {
    setFavoriteRegions((prevRegions) => [...prevRegions, newRegion]);
  };

  const removeFavoriteRegion = (regionId: string) => {
    setFavoriteRegions((prevRegions) =>
      prevRegions.filter((region) => region.id !== regionId)
    );
  };

  const addPlaylist = async (newPlaylist: {
    id: string;
    playlistName: string;
    playlistUrlName: string;
  }) => {
    console.log("Adding playlist:", newPlaylist);
    setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);

    //fetch the playlists again - ensure state is updated
    const userId = auth.currentUser?.uid;
    if (userId) {
      const updatedPlaylists = await PlaylistService.fetchPlaylists(userId);
      setPlaylists(updatedPlaylists);
    }
  };

  const removePlaylist = (playlistId: string) => {
    setPlaylists((prevPlaylists) =>
      prevPlaylists.filter((playlist) => playlist.id !== playlistId)
    );
  };

  const editPlaylist = async (playlistId: string, updatedName: string) => {
    const updatedUrlName = updatedName.toLowerCase().replace(/\s+/g, "");

    // update state optimistically
    setPlaylists((prevPlaylists) =>
      prevPlaylists.map((playlist) =>
        playlist.id === playlistId
          ? {
              ...playlist,
              playlistName: updatedName,
              playlistUrlName: updatedUrlName,
            }
          : playlist
      )
    );

    await PlaylistService.editPlaylist(playlistId, updatedName);

    const userId = auth.currentUser?.uid;
    if (userId) {
      const updatedPlaylists = await PlaylistService.fetchPlaylists(userId);
      setPlaylists(updatedPlaylists);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      if (user) {
        ChartService.fetchFavoriteCharts(user.uid).then(setFavoriteCharts);
        RegionService.fetchFavoriteRegions(user.uid).then(setFavoriteRegions);

        PlaylistService.fetchPlaylists(user.uid)
          .then(setPlaylists)
          .catch((error) => {
            console.error("Error fetching playlists:", error);
            setPlaylists([]); // Optionally reset playlists on error
          });
      } else {
        setFavoriteCharts([]);
        setFavoriteRegions([]);
        setPlaylists([]);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <SideNavigationContext.Provider
      value={{
        isFavoriteChartsExpanded,
        setIsFavoriteChartsExpanded,
        isFavoriteRegionsExpanded,
        setIsFavoriteRegionsExpanded,
        favoriteCharts,
        setFavoriteCharts,
        favoriteRegions,
        setFavoriteRegions,
        addFavoriteChart,
        removeFavoriteChart,
        addFavoriteRegion,
        removeFavoriteRegion,
        isPlaylistsExpanded,
        setIsPlaylistsExpanded,
        playlists,
        setPlaylists,
        addPlaylist,
        removePlaylist,
        editPlaylist,
        playlistModalMode,
        setPlaylistModalMode,
      }}
    >
      {children}
    </SideNavigationContext.Provider>
  );
};

export { SideNavigationContext };
