import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from '../firebase';

export const validateName = (name: string): string => {
  if (!name || name.length === 0) return "Name cannot be empty";
  const regex = /^[a-zA-Z ]*$/;
  if (!regex.test(name)) return "Name contains invalid characters";
  return "";
};

export const validateEmail = async (email: string): Promise<string> => {
  if (!email || email.length === 0) return "Email cannot be empty";
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!regex.test(email)) return "Invalid email format";

  // Check if the email is already registered
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) return "Email already registered to account";

  return "";
};

export const validateUserName = async (userName: string): Promise<string> => {
  if (!userName || userName.length === 0) return "Username cannot be empty";

  if (userName.includes(" ")) return "No spaces between names allowed";

  const regex = /^[a-zA-Z0-9 ]*$/; 
  if (!regex.test(userName)) return "Cannot contain special characters";

  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("userName", "==", userName));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) return "Username is already taken";

  return "";
};

export const validateArtistName = async (artistName: string): Promise<string> => {

  if (!artistName || artistName.length === 0) return "Artist name cannot be empty";

  const regex = /^[a-zA-Z0-9 ]*$/; 
  if (!regex.test(artistName)) return "Cannot contain special characters";


  const userName = artistName.toLowerCase().replace(/\s+/g, '');

  const usersRef = collection(firestore, "users"); 
  const q = query(usersRef, where("userName", "==", userName));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) return "Artist name is already taken";

  return "";
};

export const validateUserType = (userType: string): string => {
  if (userType === "") return "User type must be selected";
  return "";
};

export const validateState = (state: string): string => {
  if (state === "") return "State must be selected";
  return "";
};

export const validatePassword = (password: string): string => {
  if (password.length < 1) return "Password cannot be empty";
  if (password.length < 8) return "Password must be at least 8 characters";
  return "";
};

export const validateTermsCheckBox = (terms: boolean): string => {
  if (!terms) {
    return "Must agree to terms";
  }
  return "";
}
