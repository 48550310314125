import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { useSearch } from '../Context/SearchContext';
import { AudioTrack } from "./AudioTrack";
import '../Styles/SearchResults.css';
import { firestore } from "../firebase"; 
import { doc, getDoc } from "firebase/firestore";
import { Track } from '../Types/Types';
import { Spinner } from '../GlobalStyles/Spinner';
import { AudioPlayerContext } from '../Context/AudioPlayerContext'; 



// Initialize Algolia search client
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID!,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY!
);
const index = searchClient.initIndex('prod_Augio');

export const SearchResults = () => {
  const { searchQuery, setSearchQuery } = useSearch();
  const [detailedTracks, setDetailedTracks] = useState<Track[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(searchQuery); 
  const location = useLocation();



  useEffect(() => {
    const performSearch = async () => {
      const query = location.state?.query || searchQuery;
      if (query.trim()) {
        setLoading(true); 
        try {
          const result = await index.search(query);
          const trackIds = result.hits.map(hit => hit.objectID);
          await fetchTrackDetails(trackIds); 
        } catch (err) {
          console.error('Search error:', err);
        }
        setLoading(false); 
      }
    };

    performSearch();
    setInputValue(""); // Clear the input value after performing the search
  }, [searchQuery, location.state]);
  

  const fetchTrackDetails = async (trackIds: string[]) => {
    const tracks = await Promise.all(
      trackIds.map(async (id) => {
        const docRef = doc(firestore, "tracks", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return { id, ...docSnap.data() } as Track;
        } else {
          return null;
        }
      })
    ).then(tracks => tracks.filter((track): track is Track => track !== null));

    setDetailedTracks(tracks);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSearchClick = () => {
    setSearchQuery(inputValue);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchQuery(inputValue);
    }
  };

  return (
    <div className='inner-page-layout'>
      <div className='inner-page-content'> 
        <h1 className='header-titles'>Tracks</h1>
        <section className='search-input-search-page-container'>
          <input 
            className='search-input-search-page' 
            placeholder='search songs' 
            value={inputValue} 
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}  
          />
          <button 
            className='search-page-search-btn' 
            onClick={handleSearchClick}
          >
            Search
          </button>
        </section>
        <p className='search-query-text'>Results for "{searchQuery}"</p>
        <hr className='search-page-divider'></hr>
        {loading ? (
          <Spinner /> 
        ) : detailedTracks.length > 0 ? (
          detailedTracks.map(track => (
            <AudioTrack key={track.id} track={track} feedIdentifier="search" feedTracks={detailedTracks}/>
          ))
        ) : (
          <p className='search-query-text'>No results for "{searchQuery}"</p>
        )}
      </div>
    </div>
  );
};
