// Validate first name
export const validateFirstName = (name: string): string => { 
    if (!name || name.length === 0) return "First name cannot be empty";
    const regex = /^[a-zA-Z ]*$/; 
    if (!regex.test(name)) return "First name contains invalid characters";
    return "";
}

// Validate last name
export const validateLastName = (name: string): string => { 
    if (!name || name.length === 0) return "Last name cannot be empty";
    const regex = /^[a-zA-Z ]*$/; 
    if (!regex.test(name)) return "Last name contains invalid characters";
    return "";
}

// Validate full name
export const validateFullName = (name: string): string => { 
    if (!name || name.length === 0) return "Full name cannot be empty";
    const regex = /^[a-zA-Z ]*$/; 
    if (!regex.test(name)) return "Full name contains invalid characters";
    return "";
}

// Validate email
export const validateEmail = (email: string): string => { 
    if (!email || email.length === 0) return "Email cannot be empty";
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    if (!regex.test(email)) return "Invalid email format";
    return "";
}

// Validate selection from dropdown for claim type
export const validateClaimType = (type: string): string => { 
    if (!type || type.length === 0) return "Must select a claim type";
    return "";
}

// Validate selection from dropdown for context type
export const validateContentType = (type: string): string => { 
    if (!type || type.length === 0) return "Must select a content type";
    return "";
}

// Validate that claim information is not empty
export const validateClaimInfo = (details: string): string => { 
    if (!details || details.length === 0) return "Claim information cannot be empty";
    return "";
}

// Validate that claim details are not empty
export const validateClaimDetails = (details: string): string => { 
    if (!details || details.length === 0) return "Claim details cannot be empty";
    return "";
}

// Validate agreement checkboxes
export const validateAgreement1 = (isChecked: boolean): string => { 
    if (!isChecked) return "You must agree to the first condition";
    return "";
}

export const validateAgreement2 = (isChecked: boolean): string => { 
    if (!isChecked) return "You must agree to the second condition";
    return "";
}

export const validateAgreement3 = (isChecked: boolean): string => { 
    if (!isChecked) return "You must agree to the third condition";
    return "";
}
