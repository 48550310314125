//audioplayercontext

import React, { createContext, useContext, useState, useEffect } from 'react';
import { Track, AudioPlayerContextType } from "../Types/Types"
import { addTrackToRecentlyPlayed } from "../Services/RecentlyPlayedService"
import { auth } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const defaultAudioPlayerContext: AudioPlayerContextType = {
  currentTrack: null,
  isPlaying: false,
  volume: 50,
  tracks: [],
  setIsAudioPlayerVisible: (isVisible: boolean) => { },
  currentTime: 0,
  duration: 0,
  tempTime: 0,
  setTempTime: (time: number) => { throw new Error('setTempTime function not yet initialized.'); },
  setCurrentTime: (time: number) => { throw new Error('setCurrentTime function not yet initialized.'); },
  setDuration: (duration: number) => { throw new Error('setDuration function not yet initialized.'); },
  setPlaylist: (newPlaylist: Track[]) => { throw new Error('setPlaylist function not yet initialized.'); },
  setCurrentDataSource: (source: string | null) => { throw new Error('setCurrentDataSource function not yet initialized.'); },
  playTrackById: (trackId: string) => { throw new Error('playTrackById function not yet initialized.'); },
  playTrack: (track: Track) => { throw new Error('playTrack function not yet initialized.'); },
  togglePlayPause: () => { throw new Error('togglePlayPause function not yet initialized.'); },
  playNext: () => { throw new Error('playNext function not yet initialized.'); },
  playPrevious: () => { throw new Error('playPrevious function not yet initialized.'); },
  adjustVolume: (volume: number) => { throw new Error('adjustVolume function not yet initialized.'); },
  toggleRepeat: () => { throw new Error('toggleRepeat function not yet initialized.'); },  // Added placeholder for toggleRepeat
  toggleShuffle: () => { throw new Error('toggleShuffle function not yet initialized.'); }, // Added placeholder for toggleShuffle
  isRepeating: false, // Default state for repeat
  isShuffling: false, // Default state for shuffle
};

// Create the context
const AudioPlayerContext = createContext<AudioPlayerContextType>(defaultAudioPlayerContext);

export { AudioPlayerContext };

interface AudioPlayerProviderProps {
  children?: React.ReactNode; // Explicitly type the children prop
}

// Context provider component
export const AudioPlayerProvider = ({ children }: AudioPlayerProviderProps) => {
  // Function to load the last played track and position from local storage
  const loadPlaybackState = () => {
    const lastPlayedTrack = localStorage.getItem('lastPlayedTrack');
    const lastPlayedPosition = localStorage.getItem('lastPlayedPosition');

    return {
      currentTrack: lastPlayedTrack ? JSON.parse(lastPlayedTrack) : null,
      currentTime: lastPlayedPosition ? parseFloat(lastPlayedPosition) : 0,
    };
  };

  // Initialize state with loaded values
  const initialState = loadPlaybackState();
  const [currentTrack, setCurrentTrack] = useState<Track | null>(initialState.currentTrack);
  const [currentTime, setCurrentTime] = useState<number>(initialState.currentTime);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [isVisible, setIsAudioPlayerVisible] = useState(false);
  const [tempTime, setTempTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentDataSource, setCurrentDataSource] = useState<string | null>(null);
  const [isRepeating, setIsRepeating] = useState(false);
  const [isShuffling, setIsShuffling] = useState(false);
  const [shuffledTracks, setShuffledTracks] = useState<Track[]>([]);


  const updateCurrentTime = (time: number) => setCurrentTime(time);
  const updateDuration = (duration: number) => setDuration(duration);
  const updateTempTime = (time: number) => setTempTime(time);



  const playTrack = (track: Track, shouldRestart = false) => {
    setCurrentTrack(track);
    setIsPlaying(true);

    // If shouldRestart is true, and there is an ongoing play, restart the track.
    if (shouldRestart) {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.currentTime = 0;
        audio.play().catch(console.error);
      }
    }

    const userId = auth.currentUser ? auth.currentUser.uid : null;
    if (userId) {
      addTrackToRecentlyPlayed(userId, track).catch(console.error);
    }
  };



  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };


  const setPlaylist = (newPlaylist: Track[], source: string) => {
    console.log(`setPlaylist called from source: ${source}`, newPlaylist);
    if (source !== currentDataSource) {
      console.log(`Changing data source from ${currentDataSource} to ${source}`);
      setCurrentDataSource(source);
    }
    setTracks(newPlaylist);
  };

  const playTrackById = (trackId: string) => {
    const trackIndex = tracks.findIndex(track => track.id === trackId);
    if (trackIndex !== -1) {
      playTrack(tracks[trackIndex]);
    } else {
      console.error("Track not found in the current playlist.");
    }
  };

  const shuffleArray = <T,>(array: T[]): T[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };


  const toggleRepeat = () => setIsRepeating(!isRepeating);
  const toggleShuffle = () => {
    setIsShuffling(!isShuffling);
    if (!isShuffling) {
      setShuffledTracks(shuffleArray([...tracks]));
    }
  };

  const playNext = async () => {
    if (tracks.length === 0) return;
    let currentIndex = currentTrack ? tracks.findIndex(track => track.id === currentTrack.id) : -1;
    let nextIndex = currentIndex;

    if (!isRepeating) {
      if (isShuffling) {
        nextIndex = Math.floor(Math.random() * tracks.length); // Choose a random track
      } else {
        nextIndex = (currentIndex + 1) % tracks.length; // Move to the next track
      }
    }

    setCurrentTime(0)
    // Play the next track
    await playTrack(tracks[nextIndex], isRepeating);

    // Call the Firebase Function to increment the play count
    const incrementPlayCount = httpsCallable(functions, 'incrementPlayCount');
    incrementPlayCount({ trackId: tracks[nextIndex].id })
      .then(result => console.log('Play count incremented:', result.data))
      .catch(error => console.error('Error incrementing play count:', error));
  };

  const playPrevious = async () => {
    if (tracks.length === 0) return;
    let currentIndex = currentTrack ? tracks.findIndex(track => track.id === currentTrack.id) : -1;
    let prevIndex = currentIndex;

    if (!isRepeating) {
      prevIndex = currentIndex - 1;
      if (prevIndex < 0) {
        prevIndex = tracks.length - 1; // Loop back to the last track
      }
    }

    setCurrentTime(0)
    // Play the previous track
    await playTrack(tracks[prevIndex], isRepeating);

    // Call the Firebase Function to increment the play count
    const incrementPlayCount = httpsCallable(functions, 'incrementPlayCount');
    incrementPlayCount({ trackId: tracks[prevIndex].id })
      .then(result => console.log('Play count incremented:', result.data))
      .catch(error => console.error('Error incrementing play count:', error));
  };


  const savePlaybackState = () => {
    if (currentTrack) {
      localStorage.setItem('lastPlayedTrack', JSON.stringify(currentTrack));
      localStorage.setItem('lastPlayedPosition', currentTime.toString());
    }
  };

  useEffect(() => {
    savePlaybackState();
  }, [currentTrack, currentTime]);



  // Renamed this function to avoid naming conflict with setVolume state setter
  const adjustVolume = (newVolume: number) => {
    setVolume(newVolume);
  };

  return (
    <AudioPlayerContext.Provider value={{
      currentTrack, isPlaying, volume, tracks, playTrack, togglePlayPause,
      playNext, playPrevious, adjustVolume, setIsAudioPlayerVisible,
      currentTime, duration, setCurrentTime: updateCurrentTime,
      setDuration: updateDuration, setPlaylist, setCurrentDataSource, playTrackById, tempTime,
      setTempTime: updateTempTime, toggleRepeat, toggleShuffle,
      isRepeating, isShuffling
    }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};





