export const states = [
    { stateID: 1, stateName: "Alabama" },
    { stateID: 2, stateName: "Alaska" },
    { stateID: 3, stateName: "Arizona" },
    { stateID: 4, stateName: "Arkansas" },
    { stateID: 5, stateName: "California" },
    { stateID: 6, stateName: "Colorado" },
    { stateID: 7, stateName: "Connecticut" },
    { stateID: 8, stateName: "Delaware" },
    { stateID: 9, stateName: "Florida" },
    { stateID: 10, stateName: "Georgia" },
    { stateID: 11, stateName: "Hawaii" },
    { stateID: 12, stateName: "Idaho" },
    { stateID: 13, stateName: "Illinois" },
    { stateID: 14, stateName: "Indiana" },
    { stateID: 15, stateName: "Iowa" },
    { stateID: 16, stateName: "Kansas" },
    { stateID: 17, stateName: "Kentucky" },
    { stateID: 18, stateName: "Louisiana" },
    { stateID: 19, stateName: "Maine" },
    { stateID: 20, stateName: "Maryland" },
    { stateID: 21, stateName: "Massachusetts" },
    { stateID: 22, stateName: "Michigan" },
    { stateID: 23, stateName: "Minnesota" },
    { stateID: 24, stateName: "Mississippi" },
    { stateID: 25, stateName: "Missouri" },
    { stateID: 26, stateName: "Montana" },
    { stateID: 27, stateName: "Nebraska" },
    { stateID: 28, stateName: "Nevada" },
    { stateID: 29, stateName: "New Hampshire" },
    { stateID: 30, stateName: "New Jersey" },
    { stateID: 31, stateName: "New Mexico" },
    { stateID: 32, stateName: "New York" },
    { stateID: 33, stateName: "North Carolina" },
    { stateID: 34, stateName: "North Dakota" },
    { stateID: 35, stateName: "Ohio" },
    { stateID: 36, stateName: "Oklahoma" },
    { stateID: 37, stateName: "Oregon" },
    { stateID: 38, stateName: "Pennsylvania" },
    { stateID: 39, stateName: "Rhode Island" },
    { stateID: 40, stateName: "South Carolina" },
    { stateID: 41, stateName: "South Dakota" },
    { stateID: 42, stateName: "Tennessee" },
    { stateID: 43, stateName: "Texas" },
    { stateID: 44, stateName: "Utah" },
    { stateID: 45, stateName: "Vermont" },
    { stateID: 46, stateName: "Virginia" },
    { stateID: 47, stateName: "Washington" },
    { stateID: 48, stateName: "West Virginia" },
    { stateID: 49, stateName: "Wisconsin" },
    { stateID: 50, stateName: "Wyoming" },
    { stateID: 51, stateName: "Washington D.C"},
    { stateID: 52, stateName: "Puerto Rico" }
  ];

export const sortedStates = states.sort((a, b) => a.stateName.localeCompare(b.stateName));



  export const genres = [
    {genreID: 1, genreName: "Afrobeats"},
    {genreID: 2, genreName: "Alternative"},
    {genreID: 3, genreName: "Ambient"},
    {genreID: 4, genreName: "Blues"},
    {genreID: 5, genreName: "Classical"},
    {genreID: 6, genreName: "Classical Crossover"},
    {genreID: 7, genreName: "Country"},
    {genreID: 8, genreName: "Disco"},
    {genreID: 9, genreName: "Drum and Bass"},
    {genreID: 10, genreName: "Dubstep"},
    {genreID: 11, genreName: "EDM"},
    {genreID: 12, genreName: "Electronic/Dance"},
    {genreID: 13, genreName: "Folk"},
    {genreID: 14, genreName: "Funk"},
    {genreID: 15, genreName: "Gospel"},
    {genreID: 16, genreName: "Grime"},
    {genreID: 17, genreName: "Hard Rock"},
    {genreID: 18, genreName: "Heavy Metal"},
    {genreID: 19, genreName: "Hip-Hop/Rap"},
    {genreID: 20, genreName: "House"},
    {genreID: 21, genreName: "Indie"},
    {genreID: 22, genreName: "Jazz"},
    {genreID: 23, genreName: "K-Pop"},
    {genreID: 24, genreName: "Latin"},
    {genreID: 25, genreName: "Metal"},
    {genreID: 26, genreName: "New Age"},
    {genreID: 27, genreName: "Opera"},
    {genreID: 28, genreName: "Pop"},
    {genreID: 29, genreName: "Punk"},
    {genreID: 30, genreName: "R&B/Soul"},
    {genreID: 31, genreName: "Reggae"},
    {genreID: 32, genreName: "Reggaeton"},
    {genreID: 33, genreName: "Rock"},
    {genreID: 35, genreName: "Ska"},
    {genreID: 36, genreName: "Soul"},
    {genreID: 37, genreName: "Soundtrack"},
    {genreID: 38, genreName: "Techno"},
    {genreID: 39, genreName: "Trance"},
    {genreID: 40, genreName: "World Music"},
    {genreID: 41, genreName: "Salsa" },
    {genreID: 42, genreName: "Latin Pop" },
    {genreID: 43, genreName: "Latin Hip-Hop/Rap" },
  ];
  
export const sortedGenres = genres.sort((a, b) => a.genreName.localeCompare(b.genreName));
