//edituploadservice.ts
import { firestore, auth } from "../firebase";
import { doc, collection, updateDoc, getDoc, addDoc, deleteDoc, serverTimestamp } from "firebase/firestore";
import { Track } from "../Types/Types";


const BASE_URL = "https://augio.io";

function sanitizeTitle(title: string): string {
  return title.toLowerCase().split(" ").filter(Boolean).join("-");
}

export async function updateTrack(trackId: string, updateData: Partial<Track>): Promise<void> {
  const trackRef = doc(firestore, "tracks", trackId);

  try {
    // Get the current track data to check the uploadType and albumTitle if needed
    const trackSnapshot = await getDoc(trackRef);
    if (!trackSnapshot.exists()) {
      throw new Error("Track does not exist.");
    }

    const currentTrackData = trackSnapshot.data() as Track;

    // Sanitize the track title
    const sanitizedTrackTitle = sanitizeTitle(updateData.trackTitle ?? currentTrackData.trackTitle);

    // Determine the uploadType and generate the audioTrackLink accordingly
    let trackLink = "";
    if (currentTrackData.uploadType === "album") {
      const sanitizedAlbumTitle = sanitizeTitle(currentTrackData.albumTitle ?? "");
      trackLink = `${BASE_URL}/${currentTrackData.userName}/${sanitizedAlbumTitle}/${sanitizedTrackTitle}`;
    } else {
      trackLink = `${BASE_URL}/${currentTrackData.userName}/${sanitizedTrackTitle}`;
    }

    // Update the track data with the new audioTrackLink
    const updatedData = {
      ...updateData,
      trackLink
    };

    await updateDoc(trackRef, updatedData);
    console.log("Track successfully updated!");
  } catch (error) {
    console.error("Error updating track: ", error);
    throw new Error("Failed to update track.");
  }
}



 export async function deleteTrack(trackId: string): Promise<void> {
    const trackRef = doc(firestore, "tracks", trackId);
    const deletedTrackRef = collection(firestore, "deletedTracks");

    try {
      const trackSnapshot = await getDoc(trackRef);
      if (trackSnapshot.exists()) {
        const trackData = trackSnapshot.data();
        
        // Copy the track data to 'deletedTracks' with a new timestamp
        await addDoc(deletedTrackRef, {
          ...trackData,
          dateDeleted: serverTimestamp()
        });

        // Delete the track from 'tracks' collection
        await deleteDoc(trackRef);
        console.log("Track successfully deleted and archived!");
      } else {
        throw new Error("Track does not exist.");
      }
    } catch (error) {
      console.error("Error deleting track: ", error);
      throw new Error("Failed to delete track.");
    }
}