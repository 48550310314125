import { v4 as uuidv4 } from "uuid";
import { firestore, auth } from "../firebase";
import { doc, collection, addDoc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";


const BASE_URL = "https://augio.io";


export class UploadAlbumService {
  setIsLoading: (isLoading: boolean) => void;

  constructor(setIsLoading: (isLoading: boolean) => void) {
    this.setIsLoading = setIsLoading;
  }

  sanitizeTitle(title: string): string {
    return title.toLowerCase().split(" ").filter(Boolean).join("-");
  }

  async uploadMusic(albumData: any): Promise<void> {
    this.setIsLoading(true); 

    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User must be authenticated to upload music.");

      const userDocRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) throw new Error("User document does not exist.");

      const { stateID, regionID, userName, artistName } = userDoc.data();
      
      // Prepare cover art URL if cover art exists
      let coverArtUrl = "";
      if (albumData.albumArt) {
        const coverArtFileName = `${uuidv4()}_${albumData.albumArt.name}`;
        coverArtUrl = await this.uploadFile(albumData.albumArt, coverArtFileName, "Cover Art");
      }

      const sanitizedAlbumTitle = this.sanitizeTitle(albumData.albumTitle);
      
      let uploadCount = 0;
      for (const track of albumData.tracks) {
        // Prepare audio file URL
        const audioFileName = track.audioFile ? `${uuidv4()}_${track.audioFile.name}` : "";
        const audioFileUrl = track.audioFile ? await this.uploadFile(track.audioFile, audioFileName, "Audio File") : "";

        // Sanitize the track title and generate the audio track link
        const sanitizedTrackTitle = this.sanitizeTitle(track.trackTitle);
        const trackLink = `${BASE_URL}/${userName}/${sanitizedAlbumTitle}/${sanitizedTrackTitle}`;
        
        // Firestore document creation with user and track details
        const trackData = {
          albumTitle: albumData.albumTitle,
          trackTitle: track.trackTitle,
          featureArtists: track.featureArtists,
          genre: albumData.genre,
          coverArtUrl,
          audioFileUrl,
          producers: track.producers,
          songwriters: track.songwriters,
          engineers: track.engineers,
          releaseDate: albumData.releaseDate,
          uploadTimestamp: new Date(),
          uploadType: albumData.uploadType,
          allTimePlays: 0,
          last24HoursPlays: 0,
          last7DaysPlays: 0,
          likes: 0,
          userId: user.uid,
          stateID,
          regionID,
          userName,
          artistName,
          trackLink
        };
        
        await addDoc(collection(firestore, "tracks"), trackData);
        uploadCount++;

      }

      console.log("All tracks uploaded successfully.");
      this.setIsLoading(false); // End loading
    } catch (error) {
      console.error("Upload failed:", error);
      this.setIsLoading(false); // End loading on failure
    }
  }

  async uploadFile(file: File, fileName: string, fileType: string): Promise<string> {
    const functions = getFunctions();
    const generateUploadURL = httpsCallable<unknown, { signedUrl: string; objectUrl: string }>(functions, "generateUploadURL");

    const { data } = await generateUploadURL({ filename: fileName });
    const { signedUrl, objectUrl } = data;

    const response = await fetch(signedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (!response.ok) throw new Error(`Failed to upload ${fileType} to S3`);
    console.log(`${fileType} uploaded successfully to ${objectUrl}`);

    return objectUrl; // Return the object URL for use in Firestore
  }
}
