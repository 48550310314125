import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from "../firebase"; // Ensure this imports correctly your Firebase authentication instance
import { doc, getDoc, getFirestore } from 'firebase/firestore';

// Type definition for the authentication context state
export type AuthContextType = {
  currentUser: User | null; // Nullable User type, null when no user is logged in
  isLoading: boolean;       // True when the authentication state is being checked
  userType: string | null;
  handleLogout: () => Promise<void>;
  setUserType: (userType: string | null) => void; 
}

// Creating the context with an undefined initial state
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// AuthProvider component which provides the auth state to its children
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null); // State to hold the current user object or null
  const [isLoading, setIsLoading] = useState(true); // State to track if we are still loading the user state
  const [userType, setUserType] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setCurrentUser(user);
      if (user) {
        console.log(`Fetching user type for UID: ${user.uid}`);
        // Fetch user type from Firestore
        const userDocRef = doc(getFirestore(), 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          console.log("User document found:", userDoc.data());
          const userData = userDoc.data();
          setUserType(userData.userType); // Assuming 'userType' is a field in the Firestore document
        } else {
          console.error('No user document found');
          setUserType(null);
        }
      } else {
        setUserType(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Using the auth instance directly
      setUserType(null);
      setCurrentUser(null); // Optionally reset currentUser to null
    } catch (error) {
      console.error("Logout error:", error);
      // Optionally handle errors (e.g., update an error state)
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, isLoading, userType, setUserType, handleLogout }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
