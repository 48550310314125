import React, { useState } from "react";
import "../Styles/PromoteMusicModal.css";
import { PromoStepOne } from "./PromoStepOne";
import { PromoStepTwo } from "./PromoStepTwo";
import { PromoStepThree } from "./PromoStepThree";
import { PromoStepFour } from "./PromoStepFour";

interface PromoteMusicModalProps {
  showPromoModal: boolean;
  closePromoModal: () => void;
}

export const PromoteMusicModal = ({
  showPromoModal,
  closePromoModal,
}: PromoteMusicModalProps) => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <PromoStepOne nextStep={nextStep} />;
      case 2:
        return <PromoStepTwo nextStep={nextStep} previousStep={previousStep} />;
      case 3:
        return (
          <PromoStepThree nextStep={nextStep} previousStep={previousStep} />
        );
      case 4:
        return <PromoStepFour previousStep={previousStep} />;
      default:
        return <PromoStepOne nextStep={nextStep} />; 
    }
  };

  if (!showPromoModal) return null;

  return (
    <div className="promote-music-overlay" onClick={closePromoModal}>
      <div
        className="promote-music-container"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-modal-btn" onClick={closePromoModal}>
          &times;
        </button>
        {renderStep()}
      </div>
    </div>
  );
};
