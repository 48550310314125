
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  serverTimestamp
} from "firebase/firestore";
import { firestore, auth } from '../firebase'
import { UserType } from '../Utilities/UserTypes';

export class RegisterService {

  static async registerUser(data: any): Promise<void> {
    let stateID = "";
    let regionID = "";

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      const user = userCredential.user;

      if (data.state) {
        const statesRef = collection(firestore, "states");
        const q = query(statesRef, where("stateName", "==", data.state));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          stateID = doc.data().stateID; 
        });
      }

      if (!stateID) throw new Error("State ID not found for selected state.");

      if (data.region) {
        const regionsRef = collection(firestore, "regions");
        const q = query(regionsRef, where("regionName", "==", data.region));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          regionID = doc.data().regionID; 
        });
      }

      if (!regionID) throw new Error("Region ID not found for selected region.");

      let userName = data.userName;
      if (data.userType === UserType.Artist) {
        userName = data.artistName.toLowerCase().replace(/\s+/g, '');
      } else if (data.userType === UserType.Listener) {
        userName = data.userName.toLowerCase();
      }

      const userData: any = {
        name: data.name,
        email: data.email,
        userType: data.userType,
        userName: userName,
        stateID: stateID,
        regionID: regionID, 
        fans: 0,
        lastCheckedNotifications: serverTimestamp(),
        agreedToTerms: data.terms
      };

      if (data.userType === UserType.Artist) {
        userData.artistName = data.artistName;
      }

      await setDoc(doc(firestore, "users", user.uid), userData);
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  }
  
  static async fetchRegionsByState(stateID: number): Promise<any[]> {
    try {
      console.log("Fetching regions for stateID:", stateID); 
      const regionsQuery = query(
        collection(firestore, "regions"),
        where("stateID", "==", stateID) 
      );
      const querySnapshot = await getDocs(regionsQuery);
      console.log("Query Snapshot:", querySnapshot.docs); 
      const regionsList = querySnapshot.docs.map((doc) => ({
        regionID: doc.id,
        ...doc.data(),
      }));
      return regionsList;
    } catch (error) {
      console.error("Error fetching regions:", error);
      return [];
    }
  }
  
}

