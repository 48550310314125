import { auth, firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { updateEmail } from "firebase/auth";

export class FetchAccountDetails {

  async getAccountDetails(): Promise<any> {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        const userRef = doc(firestore, "users", userId);

        const docSnapshot = await getDoc(userRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();

          let stateName = "";
          let regionName = "";

          // Query for the state based on stateID
          if (userData.stateID !== undefined) {
            // Use the correct field name "stateID" in your query
            const stateQuery = query(collection(firestore, "states"), where("stateID", "==", userData.stateID));
            const stateQuerySnapshot = await getDocs(stateQuery);
            console.log("State query result:", stateQuerySnapshot.docs.map(doc => doc.data()));

            stateName = stateQuerySnapshot.docs.length > 0 ? stateQuerySnapshot.docs[0].data().stateName : "";
          }

          // Query for the region based on regionID
          if (userData.regionID !== undefined) {
            // Use the correct field name "regionID" in your query
            const regionQuery = query(collection(firestore, "regions"), where("regionID", "==", userData.regionID));
            const regionQuerySnapshot = await getDocs(regionQuery);
            console.log("Region query result:", regionQuerySnapshot.docs.map(doc => doc.data()));

            regionName = regionQuerySnapshot.docs.length > 0 ? regionQuerySnapshot.docs[0].data().regionName : "";
          }

          // Return user data along with stateName and regionName
          return {
            ...userData,
            stateName,
            regionName,
          };
        }
      }
      return null; // Return null if no user is logged in or the document doesn't exist
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }


  //add change email code here
  async changeUserEmail(newEmail: string): Promise<void> {
    const user = auth.currentUser;
    if (user) {
      try {
        await updateEmail(user, newEmail);
        console.log("Email updated successfully");
      } catch (error: any) {  // Using `any` to capture any type of thrown object
        console.error("Error updating email:", error);
        // Safely extract the error message or provide a default one
        const errorMessage = error instanceof Error ? error.message : "Failed to update email.";
        throw new Error(errorMessage);
      }
    } else {
      throw new Error("No authenticated user.");
    }
  }
  
  

}
