import React, { useState } from "react";
import { BsHddStack } from "react-icons/bs";
import { MdOutlineRssFeed } from "react-icons/md";
import "../Styles/PromoteMusicModal.css";

interface PromoStepOneProps {
  nextStep: () => void;
}

export const PromoStepOne = ({ nextStep }: PromoStepOneProps) => {
  const [selectedType, setSelectedType] = useState<string | null>(null);

  const handleCardClick = (type: string) => {
    setSelectedType(type);
  };

  return (
    <div className="promo-container">
      <p className="promo-header-text">Pick a Promotion Type</p>
      <div className="modal-content">
        <div className="promo-type-group">
          <section
            className={`promo-type-card ${
              selectedType === "vertical" ? "selected" : ""
            }`}
            onClick={() => handleCardClick("vertical")}
          >
            <BsHddStack
              className={`promo-type-icon ${
                selectedType === "vertical" ? "selected-icon" : ""
              }`}
              title="Vertical Promotion"
              aria-label="Vertical Promotion Icon"
            />
          </section>
          <p
            className={`promo-text ${
              selectedType === "vertical" ? "selected-text" : ""
            }`}
          >
            Vertical
          </p>
        </div>

        <div className="promo-type-group">
          <section
            className={`promo-type-card ${
              selectedType === "feed" ? "selected" : ""
            }`}
            onClick={() => handleCardClick("feed")}
          >
            <MdOutlineRssFeed
              className={`promo-type-icon ${
                selectedType === "feed" ? "selected-icon" : ""
              }`}
              title="Feed Promotion"
              aria-label="Feed Promotion Icon"
            />
          </section>
          <p
            className={`promo-text ${
              selectedType === "feed" ? "selected-text" : ""
            }`}
          >
            Feed
          </p>
        </div>
      </div>
      <button className="promote-modal-next-btn" onClick={nextStep}>
        Next
      </button>
    </div>
  );
};
