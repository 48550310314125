// ContextProviderComposer.tsx
import React from 'react';

interface ContextProviderComposerProps {
  contexts: React.ReactElement[];
  children: React.ReactNode;
}

export const ContextProviderComposer = ({ contexts, children }: ContextProviderComposerProps) => {
    return contexts.reduceRight(
      (kids, parent) => React.cloneElement(parent, {}, kids),
      children
    ) as React.ReactElement; // Type assertion here
  };
  

