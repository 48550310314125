import React from "react";
import { Toolbar } from "./Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioTrack } from "./AudioTrack";
import { Feed } from "./Feed";
import { AudioUpload } from "./AudioUpload";
import { AudioPlayer } from "./AudioPlayer";
import { TrendingCharts } from "./TrendingCharts";
import { TrendingRegions } from "./TrendingRegions";
import "../Styles/Home.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

export const Home = () => {
  const { currentUser, userType } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <div className="header-titles">Trending Charts</div>
          <TrendingCharts />
          <div className="header-titles">Trending Regions</div>
          <TrendingRegions />
          <div className="header-titles">Latest Releases</div>
          <section>
            <Feed />
            <section></section>
          </section>
          {userType === "Artist" && (
            <>
              <div className="header-titles">My Uploads</div>
              <section>
                <section>
                  <AudioUpload />
                </section>
              </section>
            </>
          )}
        </section>
      </section>
    </div>
  );
};
