import { firestore } from "../firebase"; 
import { doc, getDoc, collection, query, where, getDocs, addDoc, runTransaction, Timestamp, orderBy, limit } from 'firebase/firestore';
import type { Track } from "../Types/Types";

// Function to add a track to the recently played list
export const addTrackToRecentlyPlayed = async (userId: string, track: Track) => {
  // Reference to the collection where you store user play history
  const recentlyPlayedRef = collection(firestore, 'recentlyPlayed');

  // Create a query for documents with the specified userId
  const q = query(recentlyPlayedRef, where('userId', '==', userId));

  // Execute the query
  const querySnapshot = await getDocs(q);

  const trackDetails = {
    trackId: track.id,
    playedAt: Timestamp.now(),
  };

  if (!querySnapshot.empty) {
    // User document exists, so update the existing document
    const docRef = querySnapshot.docs[0].ref;

    await runTransaction(firestore, async (transaction) => {
      const docSnapshot = await transaction.get(docRef);
      if (!docSnapshot.exists()) {
        throw "Document does not exist!";
      }

      let tracks = docSnapshot.data().tracks;

      // Filter out duplicate trackIds
      tracks = tracks.filter((t: { trackId: string }) => t.trackId !== trackDetails.trackId);

      // Add new track to the beginning and keep only the latest 30 tracks
      tracks = [trackDetails, ...tracks.slice(0, 29)];

      transaction.update(docRef, { tracks });
    });

  } else {
    // No document for this user, create a new one
    await addDoc(recentlyPlayedRef, {
      userId: userId,
      tracks: [trackDetails], // Start with this track
    });
  }
};

export default addTrackToRecentlyPlayed;


const getRecentlyPlayedTracks = async (userId: string) => {
    const recentlyPlayedRef = collection(firestore, 'recentlyPlayed');
    const q = query(recentlyPlayedRef, where('userId', '==', userId), limit(1)); // Fetch the user's document
  
    const querySnapshot = await getDocs(q);
    let trackIds: string[] = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      if (docData.tracks) {
        // Extract track IDs
        trackIds = docData.tracks.map((track: { trackId: string }) => track.trackId);
      }
    });
  
    // Fetch track details for all track IDs
    const trackDetailsPromises: Promise<Track | null>[] = trackIds.map(trackId => fetchTrackDetails(trackId));
    const trackDetails = await Promise.all(trackDetailsPromises);
  
    // Filter out null values (if any)
    const filteredTrackDetails = trackDetails.filter(track => track !== null) as Track[];
  
    console.log(filteredTrackDetails);
    return filteredTrackDetails; // Returns an array of full track details
};

export { getRecentlyPlayedTracks };

  

async function fetchTrackDetails(trackId: string): Promise<Track | null> {
    const trackRef = doc(firestore, 'tracks', trackId);
    const trackSnap = await getDoc(trackRef);
    if (trackSnap.exists()) {
        const { id, ...trackData } = trackSnap.data() as Track; // Exclude id from trackData
        const trackDetails: Track = { id: trackSnap.id, ...trackData }; // Combine the id and data
        return trackDetails;
    } else {
        console.error('No track found for ID:', trackId);
        return null;
    }
}


  
  
  