import React, { useState, useEffect, useRef, useContext } from "react";
import { Notification } from "../Types/Types"; 
import { MdLibraryMusic, MdOutlineLibraryMusic } from "react-icons/md";
import { HiUserAdd } from "react-icons/hi";
import { FaTrophy } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { MdFiberNew } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import "../Styles/Notification.css";




export const NotificationItem = ({ notification }: { notification: Notification }) => {
  const getNotificationContent = (notification: Notification) => {
    let icon = <FaChartLine />;
    let message = <span>{notification.message}</span>; 

    switch (notification.type) {
      case 'top_100':
        icon = <FaTrophy className='notification-icon trophy-icon'/>;
        message = <span>Checkout The <strong>Augio</strong> Top 100!</span>;
        break;
      case 'new_fans':
        icon = <IoMdPersonAdd className='notification-icon newfan-icon'/>;
        message = <span>You have <strong>{notification.newFansCount}</strong> new fans!</span>;
        break;
      case 'track_liked':
        icon = <FaHeart className='notification-icon like-icon'/>;
        message = <span>{notification.userName} liked <strong>{notification.trackTitle}</strong></span>;
        break;
      case 'track_charting':
        icon = <BsFillBarChartFill className='notification-icon chart-icon'/>;
        message = <span><strong>{notification.trackTitle}</strong> is <strong>#{notification.chartPosition}</strong> in {notification.stateName}!</span>;
        break;
      case 'artist_new_upload':
        icon = <MdFiberNew className='notification-icon new-icon'/>;
        message = <span><strong>{notification.userName}</strong> just uploaded new tracks!</span>;
        break;
      case 'new_tracks_region':
        icon = <MdFiberNew className='notification-icon new-icon'/>;
        message = <span>New Tracks Uploaded In <strong>{notification.regionName}</strong></span>;
        break;
      case 'state_chart_top':
        icon = <FaChartLine className='notification-icon chart-icon'/>;
        message = <span>Checkout The <strong>{notification.stateName}</strong> Top 100!</span>;
        break;
      case 'track_top100_charting':
        icon = <BsFillBarChartFill className='notification-icon chart-icon'/>;
        message = <span><strong>{notification.trackTitle}</strong> is <strong>#{notification.chartPosition}</strong> on the Augio Top 100</span>;
        break;
      default:
        break;
    }

    return { icon, message };
  };
  
    // Now it's safe to use getNotificationContent function here
    const content = getNotificationContent(notification);
  
    return (
      <div className="notification-item">
        <div className="notification-description">
        <p className='notification-message'>{content.message}</p>
        {content.icon}
        </div>
        <hr className='notification-divider'></hr>
      </div>
    );
  };