import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from "firebase/auth";
import { useNavigate, useLocation } from 'react-router-dom';




export const ResetPassword = () => {
    const logoUrl: string =
    "/Augio_FullType_Logo_latest.png";
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); 
    const location = useLocation(); // To access the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get('oobCode'); // Extract oobCode from query parameters

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!oobCode) {
            setMessage('Error: No code in URL parameters.');
            return;
        }

        const auth = getAuth();
        try {
            await confirmPasswordReset(auth, oobCode, password);
            setMessage('Password has been reset successfully!');
            // Navigate to login after a short delay
            setTimeout(() => navigate('/login'), 2000);
        } catch (error: any) {
            setMessage('Error resetting password. ' + error.message);
        }
    };

    return (
        <div className="login-form-container">
            <form className="login-form" onSubmit={handleSubmit}>
            <img src={logoUrl} alt="Logo" className="login-logo" />
                <div className="login-header-text">Reset Password</div>
                <input
                    className="login-form-input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                    required
                />
                <button className="login-form-btn" type="submit">Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};