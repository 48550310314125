import React, { useState, useRef } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { LandingPage } from './Components/Landing';
import { Login } from './Components/Login';
import { Register } from './Components/Register';
import { Terms } from './Components/Terms'; 
import { PrivacyPolicy } from './Components/PrivacyPolicy';
import { MembershipTerms } from './Components/MembershipTerms'; 
import { InfringementForm } from "./Components/InfringementForm"
import { Home } from './Components/Home';
import { LikedSongs } from './Components/LikedSongs';
import { Playlist } from './Components/Playlist';
import { RecentlyPlayed } from './Components/RecentlyPlayed';
import { Discover } from './Components/Discover';
import { Chart } from './Components/Chart';
import { Region } from './Components/Region';
import { Account } from './Components/Account';
import { Top100 } from './Components/Top100'
import { Profile } from './Components/Profile'; 
import { UserType } from './Utilities/UserTypes';
import { ContextProviderComposer } from './Context/ContextProviderComposer';
import { AudioPlayerProvider } from './Context/AudioPlayerContext'; 
import { SideNavigationProvider } from './Context/SideNavigationContext'
import { AudioPlayer } from './Components/AudioPlayer';
import { SideNavigation } from './Components/SideNavigation';
import { Toolbar } from './Components/Toolbar';
import { SearchResults } from './Components/SearchResults';
import { UserContextProvider } from './Context/UserContext';
import { SearchProvider } from './Context/SearchContext';
import { ForgotPassword } from './Components/ForgotPassword';
import { ResetPassword } from './Components/ResetPassword';
import { AuthProvider } from './Context/AuthContext';
import { PrivateRoute } from "./Routes/PrivateRoute"
import { ScrollToTop } from './Utilities/PageScroll';
import { RouteTracker } from './Utilities/RouteTracker'


const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);
  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <BrowserRouter>
    <RouteTracker />
    <ScrollToTop />
    <AuthProvider>
      <ContextProviderComposer contexts={[
        <UserContextProvider />,
        <AudioPlayerProvider />,
        <SideNavigationProvider />,
        <SearchProvider />
      ]}>
         <Toolbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
         <SideNavigation isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} closeSidebar={closeSidebar} hamburgerMenuRef={hamburgerMenuRef} />

        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/register' element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/membership-terms" element={<MembershipTerms />} />
          <Route path="/copyright-infringement" element={<InfringementForm />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />

          {/* Protected Routes */}
          <Route path='/home' element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="/chart/:stateNameAlias" element={<PrivateRoute><Chart /></PrivateRoute>} />
          <Route path="/region/:regionNameAlias" element={<PrivateRoute><Region /></PrivateRoute>} />
          <Route path="/likes" element={<PrivateRoute><LikedSongs /></PrivateRoute>} />
          <Route path="/recentlyplayed" element={<PrivateRoute><RecentlyPlayed /></PrivateRoute>} />
          <Route path="/discover" element={<PrivateRoute><Discover /></PrivateRoute>} />
          <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
          <Route path="/top100" element={<PrivateRoute><Top100 /></PrivateRoute>} />
          <Route path="/search" element={<PrivateRoute><SearchResults /></PrivateRoute>} />
          <Route path="/:userName" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/playlist/:playlistUrlName" element={<PrivateRoute><Playlist key={useParams().playlistUrlName}/></PrivateRoute>} />
        </Routes>

        <AudioPlayer />
      </ContextProviderComposer>
      </AuthProvider>
  </BrowserRouter>
  );
};

export default App;