import React, { useState, useRef, forwardRef, useEffect } from "react";
import "../Styles/UploadForm.css";
import { sortedGenres } from "../Utilities/StaticFormData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ProgressSpinner } from "../GlobalStyles/ProgressSpinner";
import { FaRegFileAlt, FaRegTrashAlt } from "react-icons/fa";
import {
  validateCoverArt,
  validateTrackTitle,
  validateAlbumTitle,
  validateGenre,
  validateAudioFile,
  validateUploadTermsCheckBox
} from "../Services/UploadValidation";
import { UploadAlbumService } from "../Services/UploadAlbumService";
import {
  AlbumUploadFormData,
  AlbumUploadErrorsState,
  DatePickerInputProps,
  Track,
  ResizeAndCropImageParams
} from "../Types/Types";
import { logCustomEvent } from '../Utilities/EventConfig'

// For the custom date picker
const CustomDatePickerInput = forwardRef<
  HTMLInputElement,
  DatePickerInputProps
>(({ value, onClick }, ref) => (
  <input
  className="upload-form-input"
    onClick={onClick}
    value={value}
    ref={ref}
    readOnly
  />
));

export const UploadAlbumForm = ({
  show,
  onClose,
  uploadType,
}: {
  show: boolean;
  onClose: () => void;
  uploadType: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // For tracking drag state
  const [uploadProgress, setUploadProgress] = useState(0); // For tracking upload progress
  const [selectedCoverArtUrl, setSelectedCoverArtUrl] = useState<string | null>(null);

  const [formData, setFormData] = useState<AlbumUploadFormData>({
    albumArt: null,
    albumTitle: "",
    genre: "",
    releaseDate: new Date(),
    tracks: [
      {
        trackTitle: "",
        featureArtists: "",
        producers: "",
        songwriters: "",
        engineers: "",
        audioFile: null,
      },
    ],
    uploadType: uploadType,
    agreeToTerms: false,
  });


  const [errors, setErrors] = useState<AlbumUploadErrorsState>({
  albumArt: "",
  albumTitle: "",
  genre: "",
  tracks: [],
  terms: "", 
});
 

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index?: number,
    field?: string
  ) => {
    const { name, value } = event.target;
    if (typeof index === "number" && field) {
      // Update track fields
      let tracks = [...formData.tracks];
      tracks[index] = { ...tracks[index], [field]: value };
      setFormData({ ...formData, tracks });
    } else {
      // Update album-level fields
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleDateChange = (date: Date) => {
    setFormData({ ...formData, releaseDate: date });
  };

  const handleAddTrack = () => {
    const newTrack = {
      trackTitle: "",
      featureArtists: "",
      producers: "",
      songwriters: "",
      engineers: "",
      audioFile: null,
    };
    setFormData((prevState) => ({
      ...prevState,
      tracks: [...prevState.tracks, newTrack],
    }));
  };

  const resizeAndCropImage = ({ file, targetSize, callback }: ResizeAndCropImageParams) => {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = targetSize;
        canvas.height = targetSize;
  
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          callback(null);
          return;
        }
  
        // Calculate the best scale to fit the square dimension
        const scale = Math.max(targetSize / image.width, targetSize / image.height);
        const scaledWidth = image.width * scale;
        const scaledHeight = image.height * scale;
  
        // Calculate the centering position on the canvas
        const dx = (targetSize - scaledWidth) / 2;
        const dy = (targetSize - scaledHeight) / 2;
  
        ctx.drawImage(image, dx, dy, scaledWidth, scaledHeight);
  
        canvas.toBlob((blob) => {
          callback(blob);
        }, 'image/jpeg');
      };
      image.src = readerEvent.target?.result as string;
    };
    reader.readAsDataURL(file);
  };
  

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isAlbumArt: boolean = false,
    trackIndex?: number
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (isAlbumArt) {
        // Process the image to be square for album art
        resizeAndCropImage({
          file: file,
          targetSize: 400, // Desired square dimension
          callback: (blob) => {
            if (blob) {
              const newFile = new File([blob], file.name, { // Preserving original file name
                type: blob.type,
                lastModified: new Date().getTime()
              });
              const url = URL.createObjectURL(newFile);
              setSelectedCoverArtUrl(url);
              setFormData(prevFormData => ({ ...prevFormData, albumArt: newFile }));
            } else {
              console.error('Failed to resize and crop the image.');
            }
          }
        });
      } else if (typeof trackIndex === "number") {
        let tracks = [...formData.tracks];
        tracks[trackIndex].audioFile = file;
        setFormData({ ...formData, tracks });
      }
    }
  };
  

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
        const newTracks = Array.from(files).map(file => ({
            audioFile: file,
            coverArt: formData.albumArt, // Assuming album cover art is used for all tracks
            trackTitle: "",
            featureArtists: "",
            genre: "",
            producers: "",
            songwriters: "",
            engineers: "",
            uploadType: uploadType,
            releaseDate: formData.releaseDate,
        }));

        // Update formData with new tracks, replacing the initial placeholder if unused
        setFormData(prevFormData => ({
            ...prevFormData,
            tracks: prevFormData.tracks[0].audioFile === null && prevFormData.tracks.length === 1
                ? newTracks
                : [...prevFormData.tracks, ...newTracks]
        }));
    }
};

  

const handleDeleteAudioFile = (trackIndex: number) => {
  // Create a new array without the track at the specified index
  const updatedTracks = formData.tracks.filter((track, index) => index !== trackIndex);
  
  setFormData({ ...formData, tracks: updatedTracks });
};

  
  

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true); // Start loading

    // Validate each track and collect errors
    const trackErrors = formData.tracks.map(track => ({
      trackTitle: validateTrackTitle(track.trackTitle),
      audioFile: validateAudioFile(track.audioFile),
      // Initial placeholders, actual validation/error setting happens below
      featureArtists: "", 
      producers: "", 
      songwriters: "", 
      engineers: "", 
    }));

    // Validate album-wide fields
    const albumArtError = validateCoverArt(formData.albumArt);
    const genreError = validateGenre(formData.genre);
    const albumTitleError = validateAlbumTitle(formData.albumTitle);
    const termsError = validateUploadTermsCheckBox(formData.agreeToTerms);


    // Check for any errors in album-wide or track-specific validations
    const validationFailed = albumArtError || genreError || albumTitleError || termsError ||
                              trackErrors.some(trackError => Object.values(trackError).some(error => error));

    if (validationFailed) {
      setErrors({
        albumArt: albumArtError,
        genre: genreError,
        albumTitle: albumTitleError,
        tracks: trackErrors,
        terms: termsError
      });
      setIsLoading(false);
      return; // Stop the submission if there are validation errors
    }

    // Processing track details for featureArtists, producers, songwriters, and engineers
    const processedTracks = formData.tracks.map(track => ({
      ...track,
      featureArtists: track.featureArtists.split(',').map(name => name.trim()).filter(name => name),
      producers: track.producers.split(',').map(name => name.trim()).filter(name => name),
      songwriters: track.songwriters.split(',').map(name => name.trim()).filter(name => name),
      engineers: track.engineers.split(',').map(name => name.trim()).filter(name => name),
    }));

    const processedFormData = {
      ...formData,
      tracks: processedTracks,
    };

    const uploadService = new UploadAlbumService(isLoading => {
      setIsLoading(isLoading); // Update loading state based on the service
      if (!isLoading) {
        onClose(); // Close the form automatically when the upload process is complete or fails
      }
    });

    try {
      // Now using processedFormData with the processed track details
      await uploadService.uploadMusic(processedFormData);
      logCustomEvent('Album Uploaded');
    } catch (error) {
      console.error("Upload failed:", error);
      logCustomEvent("Album Upload Failed");
    } finally {
      setIsLoading(false);
    }
};

const handleMultipleFilesSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;
  if (files) {
    const newTracks = Array.from(files).map(file => ({
      trackTitle: "",
      featureArtists: "",
      producers: "",
      songwriters: "",
      engineers: "",
      audioFile: file,  // directly assigning the file here
    }));

    setFormData(prevFormData => ({
      ...prevFormData,
      tracks: prevFormData.tracks[0].audioFile === null && prevFormData.tracks.length === 1
          ? newTracks
          : [...prevFormData.tracks, ...newTracks]
  }));
  }
};


const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setFormData(prevFormData => ({ ...prevFormData, agreeToTerms: event.target.checked }));
};

 // Cleanup useEffect for the image URL
 useEffect(() => {
  return () => {
    if (selectedCoverArtUrl) {
      URL.revokeObjectURL(selectedCoverArtUrl);
    }
  };
}, [selectedCoverArtUrl]);
  


  return (
    <div className={`upload-form-container ${show ? "show" : ""}`}>
      <form className="upload-form" onSubmit={handleFormSubmit}>
        <div className="cover-art-box">
          {selectedCoverArtUrl && (
            <img
              src={selectedCoverArtUrl}
              alt="Selected Album Art"
              style={{ width: "150px", height: "150px" }} // Ensure it's square
            />
          )}
        </div>
        <input
          id="albumArtInput"
          name="albumArt"
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={(e) => handleFileChange(e, true)}
        />
        <label htmlFor="albumArtInput" className="add-cover-art-btn">
          Add Album Art
        </label>
        {errors.albumArt && (
          <div className="error-message">{errors.albumArt}</div>
        )}

        <div className="upload-form-input-group">
          <div className="upload-label-input-group">
            <label htmlFor="albumTitle" className="upload-form-label">
              Album Title
            </label>
            <input
              id="albumTitle"
              name="albumTitle"
              type="text"
              value={formData.albumTitle}
              onChange={(e) => handleInputChange(e)}
              className="upload-form-input"
            />
            {errors.albumTitle && (
              <div className="error-message">{errors.albumTitle}</div>
            )}
          </div>

          <div className="upload-label-input-group">
            <label htmlFor="genre" className="upload-form-label">
              Genre
            </label>
            <select
              id="genre"
              name="genre"
              value={formData.genre}
              onChange={handleInputChange}
              className="upload-form-input"
            >
              <option value="">Select Genre</option>
              {sortedGenres.map((genre) => (
                <option key={genre.genreID} value={genre.genreName}>
                  {genre.genreName}
                </option>
              ))}
            </select>
            {errors.genre && (
              <div className="error-message">{errors.genre}</div>
            )}
          </div>
        </div>

        {/* Release Date */}
        {/* 
        <div className="upload-label-input-group">
          <label className="upload-form-label">Release Date</label>
          <DatePicker
            selected={formData.releaseDate}
            onChange={handleDateChange}
            minDate={new Date()}
            customInput={<CustomDatePickerInput />}
          />
        </div>
        */}

        <hr className="upload-form-divider" />

        {formData.tracks.map((track, index) => (
          <div key={index} className="track-details">
            <div className="upload-form-input-group">
              <div className="upload-label-input-group">
                <label className="upload-form-label">Track Title</label>
                <input
                  type="text"
                  value={track.trackTitle}
                  onChange={(e) => handleInputChange(e, index, "trackTitle")}
                  className="upload-form-input"
                />
                {errors.tracks[index] && errors.tracks[index].trackTitle && (
                  <div className="error-message">
                    {errors.tracks[index].trackTitle}
                  </div>
                )}
              </div>

              {/* Repeat for Feature Artists, Producers, Songwriters, Engineers */}
              {/* Example for Feature Artists */}
              <div className="upload-label-input-group">
                <label className="upload-form-label">Feature Artists</label>
                <input
                  type="text"
                  value={track.featureArtists}
                  onChange={(e) =>
                    handleInputChange(e, index, "featureArtists")
                  }
                  className="upload-form-input"
                  placeholder="Add names separated by comma"
                />
                {errors.tracks[index] &&
                  errors.tracks[index].featureArtists && (
                    <div className="error-message">
                      {errors.tracks[index].featureArtists}
                    </div>
                  )}
              </div>
            </div>

            <div className="upload-form-input-group">
              {/* Producers */}
              <div className="upload-label-input-group">
                <label className="upload-form-label">Producers</label>
                <input
                  type="text"
                  value={track.producers}
                  onChange={(e) => handleInputChange(e, index, "producers")}
                  className="upload-form-input"
                  placeholder="Add names separated by comma"
                />
              </div>

              {/* Songwriters */}
              <div className="upload-label-input-group">
                <label className="upload-form-label">Songwriters</label>
                <input
                  type="text"
                  value={track.songwriters}
                  onChange={(e) => handleInputChange(e, index, "songwriters")}
                  className="upload-form-input"
                  placeholder="Add names separated by comma"
                />
              </div>
            </div>

            {/* Engineers */}
            <div className="upload-label-input-group">
              <label className="upload-form-label">Engineers</label>
              <input
                type="text"
                value={track.engineers}
                onChange={(e) => handleInputChange(e, index, "engineers")}
                className="upload-form-input"
                placeholder="Add names separated by comma"
              />
            </div>

            {/* Audio File Upload and Display for Each Track */}
            <div className="upload-form-file-album">
              <FaRegFileAlt className="upload-form-file-icon" />
              <p className="upload-form-filename">
                {track.audioFile ? track.audioFile.name : "Filename"}
              </p>
              <button
                type="button"
                className="upload-form-delete-file-btn"
                onClick={() => handleDeleteAudioFile(index)} // Correctly targeting the track index
              >
                <FaRegTrashAlt className="upload-form-trash-icon" />
              </button>
              {errors.tracks[index]?.audioFile && (
                <span className="error-message">
                  {errors.tracks[index].audioFile}
                </span>
              )}
            </div>
          </div>
        ))}

        {/* Handle Multiple File Selection for Tracks */}
        <input
          id="audioFileInput"
          type="file"
          multiple
          onChange={(e) => handleMultipleFilesSelect(e)}
          style={{ display: "none" }}
        />
        <label htmlFor="audioFileInput" className="upload-form-add-audio-btn">
          Add Audio Files
        </label>

        <div className="upload-terms-container">
          <input
            type="checkbox"
            checked={formData.agreeToTerms}
            onChange={handleCheckboxChange}
          />
          <span className="upload-terms-text">
            By checking this box I acknowledge I own all rights required to
            upload this music
          </span>
        </div>
        {errors.terms && <span className="error-message">{errors.terms}</span>}

        <div
          className={`drag-drop-area ${isDragging ? "dragging" : ""}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {isDragging ? (
            <p>Drop the files here...</p>
          ) : (
            <p className="drag-drop-area-text">Drag and drop files here</p>
          )}
        </div>

        <div className="upload-form-btn-group">
          <button
            type="button"
            className="close-upload-form-btn"
            onClick={onClose}
          >
            Close
          </button>
          <button
            type="submit"
            className="save-upload-btn"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <>
                <ProgressSpinner percentage={uploadProgress} />
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
