import React, { useEffect, useState, useContext } from "react";
import { firestore } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { Toolbar } from "./Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioTrack } from "./AudioTrack";
import { AudioPlayer } from "./AudioPlayer";
import "../Styles/Top100.css";
import { useNavigate } from "react-router-dom";
import { Track } from "../Types/Types";
import { AudioPlayerContext } from "../Context/AudioPlayerContext";
import { MdInfoOutline } from "react-icons/md";

export const Top100 = () => {
  const logoImage = "Augio_FullType_Logo_latest.png";
  const [topTracks, setTopTracks] = useState<Track[]>([]);

  useEffect(() => {
    const fetchTop100Chart = async () => {
      const querySnapshot = await getDocs(collection(firestore, "top100Chart"));
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        const chartTrackIDs = docSnapshot.data().chartTrackIDs as string[];

        const tracksPromises = chartTrackIDs.map(async (trackID: string) => {
          const trackRef = doc(firestore, "tracks", trackID);
          const trackSnapshot = await getDoc(trackRef);
          return trackSnapshot.exists()
            ? ({ id: trackSnapshot.id, ...trackSnapshot.data() } as Track)
            : null;
        });

        Promise.all(tracksPromises).then((tracksData) => {
          const filteredTracksData = tracksData.filter(
            (trackData) => trackData !== null
          ) as Track[];
          const tracksWithPosition = filteredTracksData.map(
            (trackData, index) => ({
              ...trackData,
              chartPosition: index + 1,
            })
          );
          setTopTracks(tracksWithPosition);
        });
      }
    };

    fetchTop100Chart();
  }, []);

  function shuffleArray(array: Track[]): Track[] {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const handleRandomizeClick = () => {
    setTopTracks((currentTracks) => {
      // shallow copy of the array to shuffle it without changing the original state
      const shuffledTracks = shuffleArray([...currentTracks]);
      return shuffledTracks;
    });
  };

  return (
    <div className="page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <img className="top100-augio-logo" src={logoImage} alt="Augio Logo" />
          <span className="header-titles">
            Top 100
            <span className="info-container">
              <MdInfoOutline className="info-eyeball" />
              <span className="tooltip">Augio Top 100 updates every Thursday</span>
            </span>
          </span>
          <button
            className="top100-randomize-btn"
            onClick={handleRandomizeClick}
          >
            Randomize
          </button>
          <section className="tracks-container">
            {topTracks.map((track) => (
              <AudioTrack
                key={track.id}
                track={track}
                showChartPosition={true}
                feedIdentifier="top100"
                feedTracks={topTracks}
              />
            ))}
          </section>
        </section>
      </section>
    </div>
  );
};
