import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";

interface Step3Props {
  nextStep: () => void;
  previousStep: () => void;
}

type CustomInputProps = {
  value?: string;
  onClick?: () => void;
};

export const PromoStepThree = ({ nextStep, previousStep }: Step3Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const CustomDatePickerInput: React.FC<CustomInputProps> = ({
    value,
    onClick,
  }) => (
    <button className="custom-date-picker-input" onClick={onClick}>
      {value || "Select Date"}
    </button>
  );

  return (
    <div className="promo-container">
      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Track Selection</p>
          <p className="promo-detail-description">
            Select the track you wish to promote
          </p>
        </div>
        <select className="promo-track-select">
          <option>Select Track</option>
          <option>Track 1</option>
          <option>Track 2</option>
        </select>
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Campaign Start Date</p>
          <p className="promo-detail-description">
            Select the day for your promo to start
          </p>
        </div>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          minDate={new Date()}
          customInput={<CustomDatePickerInput />}
        />
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Select Location</p>
          <p className="promo-detail-description">
            Select states or regions you want to target users in
          </p>
        </div>
        <p className="promo-setting-text">Locations</p>
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Select Audience</p>
          <p className="promo-detail-description">
            Select which users you want to target
          </p>
        </div>
        <div>
          <button className="promo-audience-btn artist-audience">
            Artists
          </button>
          <button className="promo-audience-btn listener-audience">
            Listeners
          </button>
        </div>
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Genre Focus</p>
          <p className="promo-detail-description">
            Select up to 3 genres to target. Users who primarily listen to these
            genres will be targeted
          </p>
        </div>
        <p className="promo-setting-text">Genres</p>
      </section>

      <div className="promo-btns-container">
        <button className="promo-back-btn" onClick={previousStep}>
          Back
        </button>
        <button className="promo-next-btn" onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
};
