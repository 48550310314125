import React, { useState, useEffect, useContext } from "react";
import { Toolbar } from "../Components/Toolbar";
import { SideNavigation } from "./SideNavigation";
import { AudioPlayer } from "./AudioPlayer";
import { AudioTrack } from "./AudioTrack";
import { useParams } from "react-router-dom";
import "../Styles/Chart.css";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { ChartService } from "../Services/ChartService"
import { firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { Track } from '../Types/Types'
import { SideNavigationContext } from "../Context/SideNavigationContext";
import { AudioPlayerContext } from '../Context/AudioPlayerContext'; 
import { logCustomEvent } from "../Utilities/EventConfig";




export const Chart = () => {
  const [isFavorited, setIsFavorited] = useState(false);
  const { stateNameAlias } = useParams();
  const [stateName, setStateName] = useState("");
  const [chartID, setChartID] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [fansCount, setFansCount] = useState(0);
  const [tracks, setTracks] = useState<Track[]>([]);
  const context = useContext(SideNavigationContext);

  if (!context) {
    // Handle the missing context, e.g., by returning null or a placeholder
    return null;
  }
  
  const { addFavoriteChart, removeFavoriteChart } = context;


  useEffect(() => {
    if (stateNameAlias) {
      ChartService.getStateNameByAlias(stateNameAlias)
        .then(fetchedStateName => {
          setStateName(fetchedStateName);
        })
        .catch(error => {
          console.error("Error fetching state name:", error);
        });

      ChartService.getChartIdByStateNameAlias(stateNameAlias)
        .then(fetchedChartID => {
          setChartID(fetchedChartID);
          // Fetch and set fans count here
          ChartService.getFansCountByChartId(fetchedChartID)
            .then(fetchedFansCount => {
              setFansCount(fetchedFansCount);
            })
            .catch(error => {
              console.error("Error fetching fans count:", error);
            });
          if (userId) {
            checkFavoriteStatus();
          }
        })
        .catch(error => {
          console.error("Error fetching chart ID:", error);
        });
    }
  }, [stateNameAlias, userId]);

  // Function to check if the chart is already favorited
  const checkFavoriteStatus = async () => {
    if (userId && chartID) {
      const favoriteCharts = await ChartService.fetchFavoriteCharts(userId);
      const isChartFavorited = favoriteCharts.some(chart => chart.id === chartID);
      setIsFavorited(isChartFavorited);
    }
  };

  const toggleFavorite = async () => {
    if (userId && chartID) {
      setIsFavorited(currentState => !currentState);
      try {
        if (!isFavorited) {
          await ChartService.toggleFavoriteChart(userId, chartID, true);
          const newChart = { id: chartID, name: stateName, stateNameAlias: stateNameAlias };
          addFavoriteChart(newChart);
          logCustomEvent('Chart_Favorited', {
            chartId: chartID,
            chartState: stateName,
            action: 'add',
            userId: userId  // Added userId here
          });
        } else {
          await ChartService.toggleFavoriteChart(userId, chartID, false);
          removeFavoriteChart(chartID);
          logCustomEvent('Chart_Unfavorited', {
            chartId: chartID,
            chartState: stateName,
            action: 'remove',
            userId: userId  // Added userId here
          });
        }
      } catch (error) {
        console.error("Error toggling favorite status:", error);
        setIsFavorited(currentState => !currentState);
      }
    } else {
      console.error("User ID or Chart ID is missing");
    }
  };
  
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        checkFavoriteStatus();
      } else {
        setUserId(null);
        setIsFavorited(false);
      }
    });

    return () => unsubscribe();
  }, [chartID]);

  useEffect(() => {
    const fetchChartTracks = async (firebaseDocId: string) => {
      const chartRef = doc(firestore, "stateCharts", firebaseDocId);
      const chartSnapshot = await getDoc(chartRef);
  
      if (chartSnapshot.exists()) {
        const chartTrackIDs = chartSnapshot.data().chartTrackIDs as string[];
        const tracksPromises = chartTrackIDs.map(async (trackID: string, index) => {
          const trackRef = doc(firestore, 'tracks', trackID);
          const trackSnapshot = await getDoc(trackRef);
          if (trackSnapshot.exists()) {
            return { 
              id: trackSnapshot.id,
              ...trackSnapshot.data(),
              chartPosition: index + 1 // Assign chart position based on index
            } as Track; // Ensure Track is a defined interface with the required fields
          } else {
            return null;
          }
        });
  
        Promise.all(tracksPromises).then((tracksData) => {
          const filteredTracksData = tracksData.filter(trackData => trackData !== null) as Track[];
          setTracks(filteredTracksData);
        });
      }
    };
  
    if (chartID) {
      fetchChartTracks(chartID);
    }
  }, [chartID]);
  

  // Add the shuffleArray function
  function shuffleArray(array: Track[]): Track[] {
    let currentIndex = array.length, randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  // This function will be called when the Randomize button is clicked
  const handleRandomizeClick = () => {
    setTracks(currentTracks => shuffleArray([...currentTracks]));
  };
  


  return (
    <div className="chart-page-container">
      <section className="inner-page-layout">
        <section className="inner-page-content">
          <div className="chart-banner">
            <h1 className="chart-name chart-details-spacing">{stateName}</h1>
            <h2 className="chart-description chart-details-spacing">TOP 50</h2>
            <h3 className="chart-fans chart-details-spacing">{fansCount.toLocaleString()} Fans</h3>
            <button className="chart-favorite-btn" onClick={toggleFavorite}>
              {isFavorited ? <FaHeart /> : <FaRegHeart />}
              Favorite
            </button>
          </div>
          <button className='chart-randomize-track-btn' onClick={handleRandomizeClick}>Randomize</button>
          <section className="tracks-container">
            {tracks.map(track => (
              <AudioTrack key={track.id} track={track} showChartPosition={true} feedIdentifier="chart" feedTracks={tracks}/>
            ))}
      </section>
        </section>
      </section>
    </div>
  );
};
