import { getAuth, User } from "firebase/auth";
import { doc, setDoc, getDoc, getDocs, updateDoc, serverTimestamp, collection, query, where, Timestamp, orderBy, startAfter, limit } from "firebase/firestore";
import { Notification } from "../Types/Types";
import { firestore } from '../firebase'; 

export class NotificationService {

  private getUser(): User {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
        console.error("No user logged in");
        throw new Error("No user logged in");
    }
    return user;
}


public async fetchNotifications(cursor?: Notification, pageSize: number = 20): Promise<Notification[]> {
    const user = this.getUser();
    const notificationsRef = collection(firestore, "notifications");
    let q = query(
        notificationsRef, 
        where("recipientId", "==", user.uid), 
        orderBy("timestamp", "desc"),
        limit(pageSize)
    );

    if (cursor) {
        q = query(q, startAfter(cursor.timestamp));
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
        ...doc.data() as Notification,
        id: doc.id
    }));
}



      public async fetchNewNotifications(): Promise<Notification[]> {
        const user = this.getUser();
        if (!user) throw new Error("No user logged in");

        const lastCheckedTimestamp = await this.fetchUserLastChecked();
        const notificationsRef = collection(firestore, "notifications");
        // Ensure the query filters notifications both by recipientId and timestamp
        const q = query(notificationsRef, 
                        where("recipientId", "==", user.uid), 
                        where("timestamp", ">", lastCheckedTimestamp));
        const snapshot = await getDocs(q);
        return snapshot.docs.map(doc => ({
            ...doc.data() as Notification,
            id: doc.id  
        }));
    }


    public async fetchUserLastChecked(): Promise<Date> {
      const user = this.getUser();
      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) throw new Error("User not found");

      const lastChecked = userDoc.data().lastCheckedNotifications as Timestamp;
      return lastChecked ? lastChecked.toDate() : new Date(0);
  }

  public async updateUserLastChecked(): Promise<void> {
      const user = this.getUser();
      const userRef = doc(firestore, 'users', user.uid);
      await updateDoc(userRef, {
          lastCheckedNotifications: serverTimestamp(),
      });
  }

}


export const notificationService = new NotificationService();
