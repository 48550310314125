import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { User } from "firebase/auth";
import { ChartService } from "../Services/ChartService";
import { RegionService } from "../Services/RegionService";
import { MdOutlineReplay } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { BsFillBarChartFill } from "react-icons/bs";
import { SideNavigationContext } from "../Context/SideNavigationContext";
import { HiHome } from "react-icons/hi";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import "../Styles/SideNavigation.css";
import { PlaylistModal } from "./PlaylistModal";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

export interface SideNavigationProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  hamburgerMenuRef: React.RefObject<HTMLDivElement>;
  closeSidebar: () => void;
}

export const SideNavigation = ({
  isOpen,
  toggleSidebar,
  closeSidebar,
  hamburgerMenuRef,
}: SideNavigationProps) => {
  const context = useContext(SideNavigationContext);
  const { pathname } = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { userName } = useParams<{ userName?: string }>();
  const location = useLocation();
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState(false);
  const [playlistModalMode, setPlaylistModalMode] = useState<
    "create" | "edit" | "delete"
  >("create");
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(
    null
  );

  if (!context) {
    throw new Error(
      "SideNavigation must be used within a SideNavigationProvider"
    );
  }

  const {
    isFavoriteChartsExpanded,
    setIsFavoriteChartsExpanded,
    isFavoriteRegionsExpanded,
    setIsFavoriteRegionsExpanded,
    favoriteCharts,
    favoriteRegions,
    isPlaylistsExpanded,
    setIsPlaylistsExpanded,
    playlists,
  } = context;

  // Define your route criteria as shown in your example
  const staticRoutes = [
    "/home",
    "/likes",
    "/discover",
    "/top100",
    "/recentlyplayed",
    "/search",
  ];
  const excludedRoutes = [
    "/",
    "/login",
    "/register",
    "/settings",
    "/help",
    "/account",
    "/forgot-password",
    "/reset-password",
    "/terms",
    "/membership-terms",
    "/privacy-policy",
    "/copyright-infringement",
  ];

  const dynamicRoutesPatterns = [
    /^\/chart\/.+/,
    /^\/region\/.+/,
    /^\/playlist\/[^/]+$/,
  ];

  // Calculate visibility based on the current route
  const isStaticRouteVisible = staticRoutes.includes(pathname);
  const isDynamicRouteVisible =
    dynamicRoutesPatterns.some((pattern) => pattern.test(pathname)) ||
    (/^\/[a-zA-Z0-9_-]+$/.test(pathname) && !excludedRoutes.includes(pathname));

  const isVisible = isStaticRouteVisible || isDynamicRouteVisible;

  const toggleFavoriteCharts = () =>
    setIsFavoriteChartsExpanded(!isFavoriteChartsExpanded);
  const toggleFavoriteRegions = () =>
    setIsFavoriteRegionsExpanded(!isFavoriteRegionsExpanded);
  const togglePlaylists = () => setIsPlaylistsExpanded(!isPlaylistsExpanded);

  const openPlaylistModal = (
    mode: "create" | "edit" | "delete",
    playlistId?: string
  ) => {
    setPlaylistModalMode(mode);
    setSelectedPlaylistId(playlistId || null);
    setIsPlaylistModalOpen(true);
  };

  const closePlaylistModal = () => setIsPlaylistModalOpen(false);

  const handlePlaylistClick = (event: React.MouseEvent) => {
    event.stopPropagation(); 
  };

  const handleEllipsisClick = (event: React.MouseEvent, playlistId: string) => {
    event.stopPropagation();
    openPlaylistModal("edit", playlistId);
  };

  const handleTogglePlaylists = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsPlaylistsExpanded(!isPlaylistsExpanded);
  };

  const getLinkClassName = (path: string, dynamic: boolean = false) => {
    const isActive = dynamic ? pathname.includes(path) : pathname === path;
    return `sidenavigation-link ${isActive ? "active" : ""}`;
  };

  const getIconClassName = (path: string, dynamic: boolean = false) => {
    const isActive = dynamic ? pathname.includes(path) : pathname === path;
    return `sidenavigation-icon ${isActive ? "active-icon" : ""}`;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        hamburgerMenuRef.current &&
        !hamburgerMenuRef.current.contains(event.target as Node) &&
        window.innerWidth <= 420
      ) {
        if (isOpen) {
          closeSidebar(); // Use closeSidebar instead of toggleSidebar
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (window.innerWidth <= 420 && isOpen) {
      closeSidebar(); // Explicitly close the sidebar on route change when on mobile
    }
  }, [pathname]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      ref={sidebarRef}
      className={`sidenavigation-container ${isOpen ? "open" : ""}`}
    >
      <Link className={getLinkClassName("/home")} to="/home">
        <div className="sidenavigation-item">
          <HiHome className={getIconClassName("/home")} />
          <span>Home</span>
        </div>
      </Link>

      <Link
        className={getLinkClassName("/recentlyplayed")}
        to="/recentlyplayed"
      >
        <div className="sidenavigation-item">
          <MdOutlineReplay className={getIconClassName("/recentlyplayed")} />
          <span>Recently Played</span>
        </div>
      </Link>

      <Link className={getLinkClassName("/likes")} to="/likes">
        <div className="sidenavigation-item">
          <FaHeart className={getIconClassName("/likes")} />
          <span>Liked Songs</span>
        </div>
      </Link>

      <Link className={getLinkClassName("/discover")} to="/discover">
        <div className="sidenavigation-item">
          <IoEyeSharp className={getIconClassName("/discover")} />
          <span>Discover</span>
        </div>
      </Link>

      <Link className={getLinkClassName("/top100")} to="/top100">
        <div className="sidenavigation-item">
          <BsFillBarChartFill className={getIconClassName("/top100")} />
          <span>Top 100</span>
        </div>
      </Link>

      <div className="sidenavigation-text-icon" onClick={toggleFavoriteCharts}>
        <span className="sidenavigation-link-header">
          Favorite Charts
          {isFavoriteChartsExpanded ? (
            <FaChevronUp className="chevron-icon" />
          ) : (
            <FaChevronDown className="chevron-icon" />
          )}
        </span>
      </div>

      {isFavoriteChartsExpanded &&
        favoriteCharts.map((chart) => (
          <Link
            key={chart.id}
            className={getLinkClassName(`/chart/${chart.stateNameAlias}`, true)}
            to={`/chart/${chart.stateNameAlias}`}
          >
            <div className="sidenavigation-item">
              <span>{chart.name}</span>
            </div>
          </Link>
        ))}

      <div className="sidenavigation-text-icon" onClick={toggleFavoriteRegions}>
        <span className="sidenavigation-link-header">
          Favorite Regions
          {isFavoriteRegionsExpanded ? (
            <FaChevronUp className="chevron-icon" />
          ) : (
            <FaChevronDown className="chevron-icon" />
          )}
        </span>
      </div>

      {isFavoriteRegionsExpanded &&
        favoriteRegions.map((region) => (
          <Link
            key={region.id}
            className={getLinkClassName(
              `/region/${region.regionNameAlias}`,
              true
            )}
            to={`/region/${region.regionNameAlias}`}
          >
            <div className="sidenavigation-item">
              <span>{region.name}</span>
            </div>
          </Link>
        ))}

      <div
        className="sidenavigation-text-icon playlists-group"
        onClick={handleTogglePlaylists}
      >
        <span className="sidenavigation-link-header">
          Playlists
          {isPlaylistsExpanded ? (
            <FaChevronUp className="chevron-icon" />
          ) : (
            <FaChevronDown className="chevron-icon" />
          )}
        </span>

        {isPlaylistsExpanded && (
          <>
            <section
              className="add-playlist-btn"
              onClick={(e) => {
                e.stopPropagation();
                openPlaylistModal("create");
              }}
            >
              <BsPlusCircle className="add-playlist-icon playlist" />
              Add Playlist
            </section>
            {playlists.map((playlist) => (
              <div key={playlist.id} className="sidenavigation-playlist">
                <Link
                  className={getLinkClassName(
                    `/playlist/${playlist.playlistUrlName}`,
                    true
                  )}
                  to={`/playlist/${playlist.playlistUrlName}`}
                  onClick={handlePlaylistClick} // Prevent collapsing when navigating
                >
                  <span className="playlist-name">{playlist.playlistName}</span>
                </Link>
                <HiOutlineDotsHorizontal
                  className="playlist-elipsis"
                  onClick={(e) => handleEllipsisClick(e, playlist.id)}
                />
              </div>
            ))}
          </>
        )}
      </div>

      {isPlaylistModalOpen && (
        <PlaylistModal
          onClose={closePlaylistModal}
          mode={playlistModalMode}
          playlistId={selectedPlaylistId || undefined} // Convert null to undefined
          initialName={
            playlists.find((playlist) => playlist.id === selectedPlaylistId)
              ?.playlistName || ""
          }
        />
      )}
    </div>
  );
};
