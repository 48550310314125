import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';


export const validateEmail = (email: string): string => {
    if (!email || email.length === 0) return "Email is missing";
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) return "Invalid email format";
    return "";
};

export const validatePassword = (password: string): string => {
    if (!password || password.length === 0) return "Password is missing";
    return "";
};
