import React, { useState } from "react";

interface Step4Props {
  previousStep: () => void;
}

export const PromoStepFour = ({ previousStep }: Step4Props) => {
  return (
    <div className="promo-contaner">
      <div className="promo-campaign-summary-container">
        <p className="promo-detail-header">Campaign Summary</p>

        <div className="promo-review-row">
          <p className="promo-review-text">Track</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <div className="promo-review-row">
          <p className="promo-review-text">Promotion Type</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <div className="promo-review-row">
          <p className="promo-review-text">Start Date</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <div className="promo-review-row">
          <p className="promo-review-text">Location</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <div className="promo-review-row">
          <p className="promo-review-text">Audience</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <div className="promo-review-row">
          <p className="promo-review-text">Genre</p>
          <p className="promo-review-text">Track Title</p>
        </div>

        <hr className="full-width-line" />

        <div className="promo-cost-overview-container">
          <p className="promo-cost-overview">Promotion Credits Used<span className='promo-cost-spend'>2</span></p>
          <p className="promo-cost-overview">Credits Remaining<span className='promo-cost-remaining'>3</span></p>
        </div>
      </div>

      <div className="promo-btns-container">
        <button className="promo-back-btn" onClick={previousStep}>
          Back
        </button>
        <button className="promo-finish-btn">Finish</button>
      </div>
    </div>
  );
};
