import React from "react";
import "../Styles/UploadSelectionModal.css";
import { MdLibraryMusic } from "react-icons/md";
import { BsFileEarmarkMusicFill } from "react-icons/bs";

interface UploadSelectionModalProps {
  onSelect: (type: string) => void;
  show: boolean;
}

export const UploadSelectionModal = React.forwardRef<
  HTMLDivElement,
  UploadSelectionModalProps
>(({ onSelect, show }, ref) => {
  return (
    <div className={`upload-selection-modal ${show ? 'show' : ''}`} ref={ref}>
  <p className='uploadselectionmodal-header'>Upload Music</p>
  <div className="button-container">
    <button className='uploadselectionmodal-card' onClick={() => onSelect('album')}>
      <span className="button-content"><MdLibraryMusic className='upload-icon'/>Album</span>
    </button>
    <button className='uploadselectionmodal-card' onClick={() => onSelect('single')}>
      <span className="button-content"><BsFileEarmarkMusicFill className='upload-icon'/>Single</span>
    </button>
  </div>
</div>

  );
});
