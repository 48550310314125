import { firestore } from '../firebase'
import { query, collection, where, getDocs } from 'firebase/firestore';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';





export class RegionService {

  // Utility function to capitalize the first letter
  static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  static async getRegionNameByAlias(regionNameAlias: string): Promise<string> {
    // Normalize the stateNameAlias to match Firestore data
    const normalizedAlias = this.capitalizeFirstLetter(regionNameAlias);
    
    console.log("Querying for normalized regionNameAlias:", normalizedAlias); // Debug log
    const q = query(collection(firestore, 'regions'), where('regionNameAlias', '==', normalizedAlias));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      const data = docSnapshot.data();
      console.log("Document Data:", data); // Debug log
      return data.regionName;
    } else {
      console.error('No document found for alias:', normalizedAlias); // Error log
      throw new Error('Region not found');
    }
  }

  static async getRegionIdByRegionNameAlias(regionNameAlias: string): Promise<string> {
    // Normalize the stateNameAlias to match Firestore data
    const normalizedAlias = this.capitalizeFirstLetter(regionNameAlias);
    
    const q = query(collection(firestore, 'regions'), where('regionNameAlias', '==', normalizedAlias));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id; 
    } else {
      throw new Error('Region not found');
    }
  }

  static async toggleFavoriteRegion(userId: string, regionId: string, isFavorited: boolean): Promise<void> {
    const favoriteRegionsDocRef = doc(firestore, 'favoriteRegions', userId);
  
    const docSnapshot = await getDoc(favoriteRegionsDocRef);
    if (!docSnapshot.exists()) {
      await setDoc(favoriteRegionsDocRef, {
        regionIDs: isFavorited ? [regionId] : [],
        userId: userId  // Include userId here
      }, { merge: true });
    } else {
      if (isFavorited) {
        await updateDoc(favoriteRegionsDocRef, {
          regionIDs: arrayUnion(regionId),
          userId: userId  // Include userId here
        });
      } else {
        await updateDoc(favoriteRegionsDocRef, {
          regionIDs: arrayRemove(regionId),
          userId: userId  // Include userId here
        });
      }
    }
  
    const regionDocRef = doc(firestore, 'regions', regionId);
    const regionDoc = await getDoc(regionDocRef);
    if (regionDoc.exists()) {
      const currentFansCount = regionDoc.data().regionFans || 0;
      const updatedFansCount = isFavorited ? currentFansCount + 1 : currentFansCount - 1;
      await updateDoc(regionDocRef, {
        regionFans: updatedFansCount
      });
    }
  }
  

  static async getFansCountByRegionId(regionId: string): Promise<number> {
    const docRef = doc(firestore, 'regions', regionId);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.regionFans; // Assuming 'chartFans' is the field name in Firestore
    } else {
      throw new Error('Region not found');
    }
  }



  static async fetchFavoriteRegions(userId: string): Promise<any[]> {
    const favoriteRegionsDocRef = doc(firestore, 'favoriteRegions', userId);
    const docSnapshot = await getDoc(favoriteRegionsDocRef);
  
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const regionIDs = data.regionIDs as string[] || [];
      const regionDetails = await Promise.all(
        regionIDs.map(async (regionId: string) => {
          const regionDocRef = doc(firestore, 'regions', regionId);
          const regionSnapshot = await getDoc(regionDocRef);
          if (regionSnapshot.exists()) {
            const regionData = regionSnapshot.data();
            return {
              id: regionId,
              name: regionData.regionName,
              regionNameAlias: regionData.regionNameAlias // Add this line to include stateNameAlias
            };
          } else {
            return { id: regionId, name: 'Unknown region', regionNameAlias: '' };
          }
        })
      );
      console.log("Favorite regions data:", regionDetails);
      return regionDetails;
    } else {
      return [];
    }
  }





}