import React, { useState } from "react";

interface Step2Props {
  nextStep: () => void;
  previousStep: () => void;
}

export const PromoStepTwo = ({ nextStep, previousStep }: Step2Props) => {
  const [reach, setReach] = useState(400); 

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReach(Number(event.target.value));
  };

  return (
    <div className="promo-container">
      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Promotion Type</p>
          <p className="promo-detail-description">
            Feed promotions allow you to promote your track within users latest
            releases feeds
          </p>
        </div>
        <p className="promo-setting-text">Feed Promotion</p>
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Select Reach</p>
          <p className="promo-detail-description">
            Target how many accounts you want your promotion to reach
          </p>
        </div>
        <div className="promo-slider-container">
          <input
            type="range"
            min="100"
            max="1000"
            value={reach}
            step="50"
            onChange={handleSliderChange}
            className="promo-slider"
          />
          <p className="promo-setting-text">{reach} Accounts</p>
        </div>
      </section>

      <hr className="full-width-line" />

      <section className="promo-detail-row">
        <div className="promo-detail-group">
          <p className="promo-detail">Credits</p>
          <p className="promo-detail-description">
            You have 5 free promotion credits
          </p>
          <p className="promo-detail-custom">
            Credits Remaining<span className="credit-cost-box">3</span>
          </p>
        </div>

        <p className="promo-setting-text">
          Credits Cost<span className="credit-cost-box">2</span>
        </p>
      </section>

      <div className="promo-btns-container">
        <button className="promo-back-btn" onClick={previousStep}>
          Back
        </button>
        <button className="promo-next-btn" onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
};
