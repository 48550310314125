import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Set scroll behavior to 'instant' or 'auto' to jump immediately to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto' // Or use 'auto' which is effectively the same in this context
    });
  }, [pathname]); // Triggered every time the pathname changes

  return null; // This component does not render anything
};


