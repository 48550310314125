import { firestore } from "../firebase";
import { 
  collection, 
  doc, 
  setDoc, 
  updateDoc, 
  writeBatch, 
  query, 
  where, 
  getDocs, 
  serverTimestamp, 
  increment 
} from 'firebase/firestore';

export class LikeTracksService {
  static async likeTrack(userId: string, trackId: string) {
    const batch = writeBatch(firestore);

    // Create a new document reference in 'likedTracks' collection
    const likedTrackRef = doc(collection(firestore, "likedTracks"));
    batch.set(likedTrackRef, {
      userId,
      trackId,
      likeDate: serverTimestamp(),
    });

    // Reference to the track that is being liked
    const trackRef = doc(firestore, "tracks", trackId);
    batch.update(trackRef, {
      likes: increment(1),
    });

    await batch.commit();
  }

  static async unlikeTrack(userId: string, trackId: string) {
    // To find the specific 'likedTrack' document, you need to query
    const likedTracksQuery = query(
      collection(firestore, "likedTracks"), 
      where("userId", "==", userId), 
      where("trackId", "==", trackId)
    );

    const querySnapshot = await getDocs(likedTracksQuery);

    if (!querySnapshot.empty) {
      const batch = writeBatch(firestore);

      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      const trackRef = doc(firestore, "tracks", trackId);
      batch.update(trackRef, {
        likes: increment(-1),
      });

      await batch.commit();
    }
  }

  static async isTrackLikedByUser(userId: string, trackId: string): Promise<boolean> {
    const likedTracksQuery = query(
      collection(firestore, "likedTracks"),
      where("userId", "==", userId),
      where("trackId", "==", trackId)
    );

    const querySnapshot = await getDocs(likedTracksQuery);
    return !querySnapshot.empty; // Returns true if the track is liked by the user, false otherwise
  }

}
