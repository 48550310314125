import { v4 as uuidv4 } from "uuid";
import { firestore, auth } from "../firebase";
import { doc, collection, addDoc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

interface UploadURLResponse {
  url: string; // Assuming the function returns an object with a url property
}

const BASE_URL = "https://augio.io";


export class UploadSingleService {
  setIsLoading: (isLoading: boolean) => void;

  constructor(setIsLoading: (isLoading: boolean) => void) {
    this.setIsLoading = setIsLoading;
  }

  sanitizeTitle(title: string): string {
    return title.toLowerCase().split(" ").filter(Boolean).join("-");
  }

  async uploadMusic(form: any): Promise<void> {
    this.setIsLoading(true); 

    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User must be authenticated to upload music.");

      // Fetch user's stateID and regionID from Firestore
      const userDocRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) throw new Error("User document does not exist.");

      const { stateID, regionID, userName, artistName } = userDoc.data();

      // First, confirm the file objects are received correctly
      console.log("Cover Art File Object:", form.coverArt);
      console.log("Audio File Object:", form.audioFile);

      // Debugging the environment variable just before constructing URLs
      console.log("S3 Bucket Name:", process.env.REACT_APP_S3_BUCKET_NAME);

      // Generate unique filenames
      const coverArtFileName = form.coverArt ? `${uuidv4()}_${form.coverArt.name}` : "";
      const audioFileName = form.audioFile ? `${uuidv4()}_${form.audioFile.name}` : "";


      // Debugging filenames after generation
      console.log("Generated coverArtFileName:", coverArtFileName);
      console.log("Generated audioFileName:", audioFileName);

      // Conditional uploads based on file presence
      if (form.coverArt) await this.uploadFile(form.coverArt, coverArtFileName, "Cover Art");
      if (form.audioFile) await this.uploadFile(form.audioFile, audioFileName, "Audio File");

      // Construct URLs for uploaded files
      // You can use the same filenames generated earlier without redeclaring the variables
      const coverArtUrl = coverArtFileName ? `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${coverArtFileName}` : "";
      const audioFileUrl = audioFileName ? `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.amazonaws.com/${audioFileName}` : "";

      // Sanitize the track title and generate the audio track link
      const sanitizedTrackTitle = this.sanitizeTitle(form.trackTitle);
      const trackLink = `${BASE_URL}/${userName}/${sanitizedTrackTitle}`;
      

      // Firestore document creation with user details
      const trackData = {
        trackTitle: form.trackTitle,
        featureArtists: form.featureArtists,
        genre: form.genre,
        coverArtUrl,
        audioFileUrl,
        producers: form.producers,
        songwriters: form.songwriters,
        engineers: form.engineers,
        releaseDate: form.releaseDate,
        uploadTimestamp: new Date(),
        uploadType: form.uploadType,
        allTimePlays: 0,
        last24HoursPlays: 0,
        last7DaysPlays: 0,
        likes: 0,
        userId: user.uid, // Include the user's UID
        stateID, // Include the user's stateID
        regionID, // Include the user's regionID
	      userName,
        artistName,
        trackLink
      };

      await addDoc(collection(firestore, "tracks"), trackData);
      this.setIsLoading(false); // End loading
    } catch (error) {
      console.error("Upload failed:", error);
      this.setIsLoading(false); // End loading
    }
  }

  async uploadFile(file: File, fileName: string, fileType: string): Promise<string> {
    const functions = getFunctions();
    const generateUploadURL = httpsCallable<unknown, { signedUrl: string; objectUrl: string }>(functions, "generateUploadURL");
  
    this.setIsLoading(true); // Set loading to true at the beginning of the upload process
  
    try {
      const { data } = await generateUploadURL({ filename: fileName });      
      const { signedUrl, objectUrl } = data;
  
      // Uploading the file to S3 using the signed URL
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
  
      if (!response.ok) throw new Error(`Failed to upload ${fileType} to S3`);
      console.log(`${fileType} uploaded successfully to ${objectUrl}`);
      this.setIsLoading(false); // Set loading to false upon successful completion
  
      return objectUrl; // Return the actual object URL for further use
    } catch (error) {
      console.error(`Error uploading ${fileType}:`, error);
      this.setIsLoading(false); // Set loading to false because the upload process has ended (due to error)
      throw error;
    }
  }
  
  
}